body.groupbuilds {

  .detail {
    margin-bottom: 25px;
  }

  a.btn {
    color: white;
    font-weight: bolder;

    &:hover {
      background-color: $main-orange;
      text-decoration: underline;
    }
  }

  .tab-content {
    min-height: 100px;
    margin-bottom: 25px;
  }

  #calendar, #build-list {
    margin-top: 15px;
    background-color: #f5f5f5;
    @include border-radius(5px);
    border: solid 1px #e3e3e3;
    padding: 0 15px;
  }

  .calendar {
    $cal-border: solid 1px $default-border-color;
    margin-bottom: 15px;

    .month-names {
      margin-left: 0;
      margin-right: 0;

      .month-name {
        background-color: rgba($main-blue, 0.6);
        padding: 5px inherit;
        color: $white;

        &:nth-child(odd) {
          background-color: rgba($main-blue,0.68);
        }

        &:first-child {
          @include border-top-left-radius(5px);
        }
        &:last-child {
          @include border-top-right-radius(5px);
        }
      }
    }

    > div:last-child {
      @include border-bottom-left-radius(5px);
      @include border-bottom-right-radius(5px);
    }


    .today-marker {
      width: 3px;
      position: absolute;
      top: -5px;
      height: 100px;
      border: dotted 1px rgba($main-blue, 0.3);
      border-top: none;
      border-bottom: none;
      margin-bottom: -10px;
      z-index: 2;
    }

    .gb-wrapper {
      $spacing: 20px;
      overflow: hidden;
      padding: $spacing 0 0;
      border-left: $cal-border;
      border-right: $cal-border;
      position: relative;
      margin-left: 0;
      margin-right: 0;
      background-color: $white;

      .calendar-gb {
        border: $cal-border;
        text-align: center;
        padding: 0;

        a {
          display: block;
          padding: 5px;
          color: $white;
          font-weight: bold;
          @include ellipsis;

          span.end {
            font-size: 80%;
            line-height: 20px;
            float:right;
          }
        }
      }

      &.last {
        padding-bottom: $spacing;
        border-bottom: $cal-border;
      }
    }
  }

  .category-icon {
    position: absolute;
    top: 25px;
    right: 50px;
    font-size: 500%;
    color: rgba($main-blue, 0.3);
  }

  #build-list .tab-content {
    padding: 15px;
    background-color: $white;
    border: solid 1px #DDD;
    border-top: none;
  }

  .participants tr {
    .fa-ellipsis-h {
      @include opacity(0.25);
    }

    .fa-check {
      display: none;
    }

    &.finished {

      .fa-ellipsis-h {
        display: none;
      }

      .fa-check {
        display: inline-block;
        color: $main-green;
      }
    }
  }
}

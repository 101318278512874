/*
* Hide the dotted lines around an element when it receives focus.
*/

* { _noFocusLine: expression(this.hideFocus=true); } /* ie7 */
::-moz-focus-inner {border:0;}                       /* firefox */
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
:focus, a:focus {outline:none;}                      /* ie8, chrome, etc */


@mixin ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.r {
  background: url('../images/logo/mb_r.png') no-repeat top right;
  background-size: 25px 42px;
}

.relative {
    position: relative;
}


@mixin bw(){
  //Internet Explorer 6 - 9
  filter: gray;

  //Chrome 19+
  -webkit-filter: grayscale(100%);

  //Firefox 10+
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");

  //Standard
  filter: grayscale(100%);
}

@mixin center-vertical() {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

@mixin raised($dp: 1) {
  @include box-shadow(0 0 $dp*5px 0 rgba($black, 0.4), 0 $dp*5px $dp*7px 0 rgba($black, 0.3));
}

@mixin text-raised($dp: 1) {
  @include text-shadow(0 0 $dp*5px 0 rgba($black, 0.4), 0 $dp*5px $dp*7px 0 rgba($black, 0.3));
}

@mixin menu-feedback() {
  @include transition(all 1s ease);

  &:hover,
  &:focus,
  &:active {
    background-color: lighten($main-blue, 10);
    @include box-shadow(0 0 10px rgba($black, 0.1) inset);
    color: $white;
  }
}

// top down
@mixin text-background() {
  $gradient: linear-gradient(180deg, rgba(black, .5), rgba(black, .1));
  @include background($gradient);
}


@mixin selected-fa($color: $main-green, $raise: 1) {
  & + label + .fa {
    display: none;
    position: absolute;
    top: 5px;
    right: 0.6em;
    color: $color;
    @include text-raised($raise);
  }

  &:checked + label {
    @include raised($raise);

    + .fa {
      display: block;
    }
  }
}

.cart {
  padding: 20px;

  &__row {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  &__box {
    border: $border-light;
    @include border-radius(6px);
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: flex-end;
    flex-direction: column;

    &:hover {
      cursor: pointer;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    margin-left: 20px;
  }

  &__price {
    font-weight: bold;
  }

  &__icon {
    color: $main-blue;
    font-size: 24px;
  }


  &__actions {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: $border-light;

    .button {
      font-size: 12px;
    }
  }

  &__container {
    transition: all .1s linear;

    &--expanded {
      .cart {
        &__menu {
          opacity: 1;
          visibility: visible;
          animation-delay: 5s;
          transition: all .3s linear;
        }

        &__box {
          @include border-radius(6px 6px 0 0);
          margin-bottom: -1px;
        }
      }
    }
  }

  &__menu {
    opacity: 0;
    visibility: hidden;
    border: $border-light;
    @include box-shadow(0 2px 8px 0 rgba(186, 198, 208, 0.5), 0 2px 8px 0 rgba(0, 157, 255, 0.05));
    overflow: hidden;
    position: absolute;
    right: 0;
    background: $white;
    width: 380px;
    @include border-radius(6px 0 6px 6px);
  }

  &__products {
    overflow-y: scroll;
    max-height: 300px;
  }
}

.controls {

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__amount {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    text-align: center;
  }
}

.cart-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  text-align: center;

  &:not(last-child) {
    border-bottom: $border-light;
  }

  &__image {
    width: 15%;

    img {
      width: 40px;
      height: 40px;
    }
  }

  &__name {
    font-size: 12px;
    width: 45%;
    padding: 0 5px;
  }

  &__amount {
    width: 10%;
  }

  &__price {
    width: 25%;
  }

  &__remove {
    cursor: pointer;
    width: 5%;
  }
}

.cart-totals {
  float: right;
  padding: 20px;
  width: 250px;

  &__row {
    display: flex;
    justify-content: space-between;
    line-height: 2;
  }

  &__text {
    margin-right: 10px;
  }

  &__value {
    font-weight: bold;
  }
}

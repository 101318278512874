// center the top navbar
.navbar-center {
  .navbar-nav {
    display: inline-block;
    float: none;
  }
  .navbar-collapse {
    text-align: center;
  }
}

#main-nav li.tab {
  font-family: $font-header-nav;
}

body.fade-small-logo .navbar-brand img {
  @include bw();
  @include opacity(0.7);
}

@media (min-width:768px) { // show the tabs
  .navbar-brand {
    position: absolute;
    padding: 0;
    top: 0;
    left: 30px;
    img {
      height: 35px;
      width: 184px;
    }
  }
  .navbar-collapse.collapse {
    height: 35px !important;
  }
  .sprue-tabs {
    // hide all the default styling from bootstrap
    @include background($nav-gradient);
    @include box-shadow(none);
    border: none;
    min-height: 35px;
    max-height: 35px;
    margin: 0 -15px;

    li.tab > a {
      color: $nav-tab-text;
      background-color: $nav-tab;
      display: block;
      padding: 3px 20px 6px;
      margin: 5px 10px 0;
      font-size: 115%;
      @include border-top-radius(10px);
      @include text-shadow(#AAA 1px 1px 3px, #AAA 1px 1px 1px, #BBB 1px 1px 0, #BBB 0 0 1px);
      border: solid 1px #c8c8c8;
      border-bottom: none;
      @include box-shadow(#AAA 0 -1px 1px -1px, 3px 0 4px rgba(0,0,0,0.1), inset $broken-white 0 2px 1px);
      overflow: hidden; // cut off bottom shadow
      &:hover, &:focus, &:active, &.active {
        background-color: $nav-tab-highlight;
        color: $nav-tab-text-highlight;
        padding-top: 5px;
        margin-top: 3px;
      }
    }
  }
}

@media (max-width: 768px) { // collapsed, take up the entire width with the background
  .navbar-brand {
    height: 50px;
    padding: 7.5px 15px;
    img {
      height: 35px;
      width: 184px;
    }
  }
  .navbar-center {
    .navbar-collapse {
      text-align: left;
      padding: 0;
    }
    .navbar-nav {
      width: 100%;
      margin: 0;
    }
  }
  li.tab {
    border: solid 1px $nav-tab;
    @include background($nav-gradient);
    &:hover, &.active, &:focus, &:active {
      @include background($nav-gradient-reverse);
    }
  }
}


/*
 * Shop banner
 */
@media (min-width:650px) {
  #shop-banner {
    text-align: center;
    padding: 5px 15px 0;
    @include box-shadow(inset #c8c8c8 0 5px 5px -5px);
    margin: 0 -15px;
    font-size: 0;
    li {
      display: inline-block;
      a {
        display: block;
        height: 25px;
        background-image: url('../images/shop/products_sprite.png');
        background-repeat: no-repeat;
      }
    }
  }
}
@media (max-width:650px) {
  #shop-banner {
    display: none;
  }
}

/* brands, individual */
#shop-uhu{
    width: 54px;
    background-position: 0px -293px;
}
#shop-xuron{
    width: 102px;
    background-position: -54px -293px;
}
#shop-revell{
    width: 62px;
    background-position: -156px -293px;
}
#shop-alclad{
    width: 65px;
    background-position: -218px -293px;
}
#shop-bmf{
    width: 66px;
    background-position: -283px -293px;
}
#shop-birchwood{
    width: 52px;
    background-position: -349px -293px;
}
#shop-evergreen{
    width: 50px;
    background-position: -516px -318px;
}
#shop-sp{
    width: 74px;
    background-position: -442px -318px;
}
#shop-excel{
    width: 56px;
    background-position: -442px -293px;
}
#shop-intech{
    width: 86px;
    background-position: -498px -293px;
}
#shop-microscale{
    width: 62px;
    background-position: 0 -318px;
}
#shop-miliput{
    width: 93px;
    background-position: -62px -318px;
}
#shop-mb{
    width: 127px;
    background-position: -155px -318px;
}
#shop-rustall{
    width: 93px;
    background-position: -282px -318px;
}
#shop-sms{
    width: 25px;
    background-position: -375px -318px;
}
#shop-vallejo{
    width: 42px;
    background-position: -400px -318px;
}


header {

  .dropdown-toggle {
    @include menu-feedback();
  }

  .dropdown-menu {
    top: auto;
    right: 0;
    left: auto;
    @include border-radius(3px);
    background-color: $main-blue;
    padding: 0;

    > li > a {
      color: $white;
      font-weight: bold;
      @include menu-feedback();
    }
  }
}

/*
 * Headers
 */
.h1-margin {
  margin-top: 34px;
  margin-bottom: 10px;
}

h1, .h1 {
  color: $main-blue;
}

h2, .h2, h3, .h3, h4, .h4 {
  color: rgba($main-blue, 0.9);
}

h1, h2, h3, h4, h5, h6 {
  &.align-tabs {
    margin-top: -5px;
  }
}

/*
 * Messages
 */
ul.messages {
  margin-top: 15px;
}

.confirmed-model {
  h2, h3, h4 {
    margin-top: 10px;
  }
}

a.list-group-item:hover {
  background-color: rgba($main-light-blue, 0.5);
  color: white;
}

.eye-catcher {
  background-color: $main-light-blue;
  color: rgba($white, 0.9);
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-top: solid 2px $main-grey;
  border-bottom: solid 2px $main-grey;

  h1, h2, h3, h4, h5, h6 {
    small {
      color: rgba($white, 0.4);
    }
  }

  h1, .h1 {
    color: rgba($white, 0.95);
  }

  a {
    color: $main-blue;
    font-weight: bold;

    &.btn {
      color: $white;
    }
  }
}

p {
  margin-top: 10px;
}

.feedback {
  p {
    background-color: rgba($main-pink, 0.4);
    margin-left: -5px;
    margin-right: -5px;
    padding: 5px;
  }
}

body > footer {
  margin-top: 50px;
}

.popover {
  background-color: $main-green;
  color: $white;
  @include text-shadow(1px 1px 0 darken($main-green, 15));

  @each $position in $positions {
    &.#{$position} > .arrow:after {
      border-#{$position}-color: $main-green;
    }
  }
}

.inline {
  display: inline;
}

// overlay over thumbnail
a.thumbnail + ul.list-inline {
  position: absolute;
  // 4px padding for thumbnails in bootstrap
  top: 4px + 2px;
  right: 15px + 4px + 2px;

  @include background(rgba($black, 0.2));
  @include border-radius(3px);

  &:hover {
    @include background(rgba($black, 0.7));
    @include raised(1);
  }

  > li {
    padding-left: 0;
    padding-right: 0;

    &:first-child {
      padding-left: 2px;
    }

    &:last-child {
      padding-right: 2px;
    }
  }

  a {
    color: $white;
  }
}

.product {

  &__image {
    width: 100%;
  }

  &__image-wrapper {
    width: 250px;
    height: 250px;
  }

  &__name {
    color: $text-color;
    margin: 0;
  }

  &__inner {
    display: flex;
    padding: 30px 0;
    flex-flow: wrap;
  }

  &__brand-logo {
    height: 50px;
  }

  &__content, &__inner {
    width: 100%;
  }

  &__image-container, &__info {
    width: 50%;
  }

  &__amount-label {
    display: none;
  }

  &__amount {
    width: 50px;
    padding: 0 5px;
    border: 1px solid $main-blue;
    @include border-radius(4px 0 0 4px);

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__order {
    display: flex;
    padding: 15px 0;
  }

  &__brand {
    margin: 0;
  }

  &__brand-link {
    color: $main-grey;
    text-transform: uppercase;
  }

  &__text-group {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
  }

  &__label {
    font-size: 12px;
    color: $main-grey;
  }

  &__text {
    font-size: 14px;
    font-weight: bold;

    &--lg {
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__tabs {
    width: 100%;
    margin-top: 30px;

    .nav-tabs {
      border-bottom: none;
    }
  }

  &__tab-content {
    padding: 10px 20px;
    border: 1px solid #ddd;
    @include border-radius(0 4px 4px 4px);
  }

  &__actions {
    display: flex;

    .button-row:first-child {
      margin-right: 30px;
    }
  }

  &__review-item:not(:last-child) {
    padding: 10px 0;
    border-bottom: $border;
  }

  &__leave-review {
    margin-top: 40px;
    color: $text-color;
  }

  &__rating {
    display: flex;
    align-items: center;
    padding: 5px 0;

    .fa {
      font-size: 18px;
      color: $main-orange;
    }
  }

  &__rating-info {
    margin-left: 10px;
  }
}

.review {
  &__info {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__reviewer-name {
    font-weight: bold;
    margin-right: 10px;
  }

  &__date {
    font-size: 12px;
    color: $main-grey;
  }
}

/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */
@keyframes bs-notify-fadeOut {
  0% {
    opacity: 0.9; }
  100% {
    opacity: 0; } }

select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
  vertical-align: middle; }
  .bootstrap-select > .dropdown-toggle {
    position: relative;
    width: 100%;
    text-align: right;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: space-between; }
    .bootstrap-select > .dropdown-toggle:after {
      margin-top: -1px; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
      color: #999; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
      color: rgba(255, 255, 255, 0.5); }
  .bootstrap-select > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
    z-index: 0 !important; }
    .bootstrap-select > select.mobile-device {
      top: 0;
      left: 0;
      display: block !important;
      width: 100% !important;
      z-index: 2 !important; }
  .has-error .bootstrap-select .dropdown-toggle,
  .error .bootstrap-select .dropdown-toggle,
  .bootstrap-select.is-invalid .dropdown-toggle,
  .was-validated .bootstrap-select select:invalid + .dropdown-toggle {
    border-color: #b94a48; }
  .bootstrap-select.is-valid .dropdown-toggle,
  .was-validated .bootstrap-select select:valid + .dropdown-toggle {
    border-color: #28a745; }
  .bootstrap-select.fit-width {
    width: auto !important; }
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 220px; }
  .bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
  .bootstrap-select .dropdown-toggle:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
  height: auto; }
  :not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
    width: 100%; }
  .bootstrap-select.form-control.input-group-btn {
    float: none;
    z-index: auto; }

.form-inline .bootstrap-select,
.form-inline .bootstrap-select.form-control:not([class*="col-"]) {
  width: auto; }

.bootstrap-select:not(.input-group-btn), .bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.dropdown-menu-right, .bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right; }

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0; }
  .form-group-lg .bootstrap-select.form-control .dropdown-toggle,
  .form-group-sm .bootstrap-select.form-control .dropdown-toggle {
    height: 100%;
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem; }

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem; }

.form-inline .bootstrap-select .form-control {
  width: 100%; }

.bootstrap-select.disabled,
.bootstrap-select > .disabled {
  cursor: not-allowed; }
  .bootstrap-select.disabled:focus,
  .bootstrap-select > .disabled:focus {
    outline: none !important; }

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important; }
  .bootstrap-select.bs-container .dropdown-menu {
    z-index: 1060; }

.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  flex: 0 1 auto; }
  .bs3.bootstrap-select .dropdown-toggle .filter-option {
    padding-right: inherit; }
  .input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
    position: absolute;
    padding-top: inherit;
    padding-bottom: inherit;
    padding-left: inherit;
    float: none; }
    .input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option .filter-option-inner {
      padding-right: inherit; }

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .filter-expand {
  width: 0 !important;
  float: left;
  opacity: 0 !important;
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit; }

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%; }

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bootstrap-select .dropdown-menu > .inner:focus {
    outline: none !important; }
  .bootstrap-select .dropdown-menu.inner {
    position: static;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none; }
  .bootstrap-select .dropdown-menu li {
    position: relative; }
    .bootstrap-select .dropdown-menu li.active small {
      color: rgba(255, 255, 255, 0.5) !important; }
    .bootstrap-select .dropdown-menu li.disabled a {
      cursor: not-allowed; }
    .bootstrap-select .dropdown-menu li a {
      cursor: pointer;
      user-select: none; }
      .bootstrap-select .dropdown-menu li a.opt {
        position: relative;
        padding-left: 2.25em; }
      .bootstrap-select .dropdown-menu li a span.check-mark {
        display: none; }
      .bootstrap-select .dropdown-menu li a span.text {
        display: inline-block; }
    .bootstrap-select .dropdown-menu li small {
      padding-left: 0.5em; }
  .bootstrap-select .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: 96%;
    margin: 0 2%;
    min-height: 26px;
    padding: 3px 5px;
    background: whitesmoke;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    pointer-events: none;
    opacity: 0.9;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .bootstrap-select .dropdown-menu .notify.fadeOut {
      animation: 300ms linear 750ms forwards bs-notify-fadeOut; }

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline; }

.bootstrap-select.fit-width .dropdown-toggle .bs-caret:before {
  content: '\00a0'; }

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px; }

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  transform-style: preserve-3d;
  transform: rotate(45deg); }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before, .bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
  display: block; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bs-actionsbox .btn-group button {
    width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bs-donebutton .btn-group button {
    width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none; }

/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  border-radius: 4px;
  direction: ltr; }

.datepicker-inline {
  width: 220px; }

.datepicker-rtl {
  direction: rtl; }

.datepicker-rtl.dropdown-menu {
  left: auto; }

.datepicker-rtl table tr td span {
  float: right; }

.datepicker-dropdown {
  top: 0;
  left: 0;
  padding: 4px; }

.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute; }

.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute; }

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px; }

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px; }

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px; }

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px; }

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px; }

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px; }

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid rgba(0, 0, 0, 0.15); }

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff; }

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.datepicker table tr td,
.datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: none; }

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent; }

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #777777; }

.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: #eeeeee;
  cursor: pointer; }

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #777777;
  cursor: default; }

.datepicker table tr td.highlighted {
  color: #000;
  background-color: #d9edf7;
  border-color: #85c5e5;
  border-radius: 0; }

.datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.focus {
  color: #000;
  background-color: #afd9ee;
  border-color: #298fc2; }

.datepicker table tr td.highlighted:hover {
  color: #000;
  background-color: #afd9ee;
  border-color: #52addb; }

.datepicker table tr td.highlighted:active,
.datepicker table tr td.highlighted.active {
  color: #000;
  background-color: #afd9ee;
  border-color: #52addb; }

.datepicker table tr td.highlighted:active:hover,
.datepicker table tr td.highlighted.active:hover,
.datepicker table tr td.highlighted:active:focus,
.datepicker table tr td.highlighted.active:focus,
.datepicker table tr td.highlighted:active.focus,
.datepicker table tr td.highlighted.active.focus {
  color: #000;
  background-color: #91cbe8;
  border-color: #298fc2; }

.datepicker table tr td.highlighted.disabled:hover,
.datepicker table tr td.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.highlighted:hover,
.datepicker table tr td.highlighted.disabled:focus,
.datepicker table tr td.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.disabled.focus,
.datepicker table tr td.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.highlighted.focus {
  background-color: #d9edf7;
  border-color: #85c5e5; }

.datepicker table tr td.highlighted.focused {
  background: #afd9ee; }

.datepicker table tr td.highlighted.disabled,
.datepicker table tr td.highlighted.disabled:active {
  background: #d9edf7;
  color: #777777; }

.datepicker table tr td.today {
  color: #000;
  background-color: #ffdb99;
  border-color: #ffb733; }

.datepicker table tr td.today:focus,
.datepicker table tr td.today.focus {
  color: #000;
  background-color: #ffc966;
  border-color: #b37400; }

.datepicker table tr td.today:hover {
  color: #000;
  background-color: #ffc966;
  border-color: #f59e00; }

.datepicker table tr td.today:active,
.datepicker table tr td.today.active {
  color: #000;
  background-color: #ffc966;
  border-color: #f59e00; }

.datepicker table tr td.today:active:hover,
.datepicker table tr td.today.active:hover,
.datepicker table tr td.today:active:focus,
.datepicker table tr td.today.active:focus,
.datepicker table tr td.today:active.focus,
.datepicker table tr td.today.active.focus {
  color: #000;
  background-color: #ffbc42;
  border-color: #b37400; }

.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled:focus,
.datepicker table tr td.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.today:focus,
.datepicker table tr td.today.disabled.focus,
.datepicker table tr td.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.today.focus {
  background-color: #ffdb99;
  border-color: #ffb733; }

.datepicker table tr td.today.focused {
  background: #ffc966; }

.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:active {
  background: #ffdb99;
  color: #777777; }

.datepicker table tr td.range {
  color: #000;
  background-color: #eeeeee;
  border-color: #bbbbbb;
  border-radius: 0; }

.datepicker table tr td.range:focus,
.datepicker table tr td.range.focus {
  color: #000;
  background-color: #d5d5d5;
  border-color: #7c7c7c; }

.datepicker table tr td.range:hover {
  color: #000;
  background-color: #d5d5d5;
  border-color: #9d9d9d; }

.datepicker table tr td.range:active,
.datepicker table tr td.range.active {
  color: #000;
  background-color: #d5d5d5;
  border-color: #9d9d9d; }

.datepicker table tr td.range:active:hover,
.datepicker table tr td.range.active:hover,
.datepicker table tr td.range:active:focus,
.datepicker table tr td.range.active:focus,
.datepicker table tr td.range:active.focus,
.datepicker table tr td.range.active.focus {
  color: #000;
  background-color: #c3c3c3;
  border-color: #7c7c7c; }

.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled:focus,
.datepicker table tr td.range[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range:focus,
.datepicker table tr td.range.disabled.focus,
.datepicker table tr td.range[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.focus {
  background-color: #eeeeee;
  border-color: #bbbbbb; }

.datepicker table tr td.range.focused {
  background: #d5d5d5; }

.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:active {
  background: #eeeeee;
  color: #777777; }

.datepicker table tr td.range.highlighted {
  color: #000;
  background-color: #e4eef3;
  border-color: #9dc1d3; }

.datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.focus {
  color: #000;
  background-color: #c1d7e3;
  border-color: #4b88a6; }

.datepicker table tr td.range.highlighted:hover {
  color: #000;
  background-color: #c1d7e3;
  border-color: #73a6c0; }

.datepicker table tr td.range.highlighted:active,
.datepicker table tr td.range.highlighted.active {
  color: #000;
  background-color: #c1d7e3;
  border-color: #73a6c0; }

.datepicker table tr td.range.highlighted:active:hover,
.datepicker table tr td.range.highlighted.active:hover,
.datepicker table tr td.range.highlighted:active:focus,
.datepicker table tr td.range.highlighted.active:focus,
.datepicker table tr td.range.highlighted:active.focus,
.datepicker table tr td.range.highlighted.active.focus {
  color: #000;
  background-color: #a8c8d8;
  border-color: #4b88a6; }

.datepicker table tr td.range.highlighted.disabled:hover,
.datepicker table tr td.range.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.highlighted:hover,
.datepicker table tr td.range.highlighted.disabled:focus,
.datepicker table tr td.range.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.disabled.focus,
.datepicker table tr td.range.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.highlighted.focus {
  background-color: #e4eef3;
  border-color: #9dc1d3; }

.datepicker table tr td.range.highlighted.focused {
  background: #c1d7e3; }

.datepicker table tr td.range.highlighted.disabled,
.datepicker table tr td.range.highlighted.disabled:active {
  background: #e4eef3;
  color: #777777; }

.datepicker table tr td.range.today {
  color: #000;
  background-color: #f7ca77;
  border-color: #f1a417; }

.datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.focus {
  color: #000;
  background-color: #f4b747;
  border-color: #815608; }

.datepicker table tr td.range.today:hover {
  color: #000;
  background-color: #f4b747;
  border-color: #bf800c; }

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today.active {
  color: #000;
  background-color: #f4b747;
  border-color: #bf800c; }

.datepicker table tr td.range.today:active:hover,
.datepicker table tr td.range.today.active:hover,
.datepicker table tr td.range.today:active:focus,
.datepicker table tr td.range.today.active:focus,
.datepicker table tr td.range.today:active.focus,
.datepicker table tr td.range.today.active.focus {
  color: #000;
  background-color: #f2aa25;
  border-color: #815608; }

.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled:focus,
.datepicker table tr td.range.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.disabled.focus,
.datepicker table tr td.range.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.today.focus {
  background-color: #f7ca77;
  border-color: #f1a417; }

.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:active {
  background: #f7ca77;
  color: #777777; }

.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #777777;
  border-color: #555555;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td.selected:focus,
.datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.focus,
.datepicker table tr td.selected.highlighted.focus {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #161616; }

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.highlighted:hover {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #373737; }

.datepicker table tr td.selected:active,
.datepicker table tr td.selected.highlighted:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.highlighted.active {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #373737; }

.datepicker table tr td.selected:active:hover,
.datepicker table tr td.selected.highlighted:active:hover,
.datepicker table tr td.selected.active:hover,
.datepicker table tr td.selected.highlighted.active:hover,
.datepicker table tr td.selected:active:focus,
.datepicker table tr td.selected.highlighted:active:focus,
.datepicker table tr td.selected.active:focus,
.datepicker table tr td.selected.highlighted.active:focus,
.datepicker table tr td.selected:active.focus,
.datepicker table tr td.selected.highlighted:active.focus,
.datepicker table tr td.selected.active.focus,
.datepicker table tr td.selected.highlighted.active.focus {
  color: #fff;
  background-color: #4c4c4c;
  border-color: #161616; }

.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.highlighted.disabled:hover,
.datepicker table tr td.selected[disabled]:hover,
.datepicker table tr td.selected.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.selected:hover,
fieldset[disabled] .datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected.disabled:focus,
.datepicker table tr td.selected.highlighted.disabled:focus,
.datepicker table tr td.selected[disabled]:focus,
.datepicker table tr td.selected.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.selected:focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.disabled.focus,
.datepicker table tr td.selected.highlighted.disabled.focus,
.datepicker table tr td.selected[disabled].focus,
.datepicker table tr td.selected.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.selected.focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted.focus {
  background-color: #777777;
  border-color: #555555; }

.datepicker table tr td.active,
.datepicker table tr td.active.highlighted {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td.active:focus,
.datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.focus,
.datepicker table tr td.active.highlighted.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40; }

.datepicker table tr td.active:hover,
.datepicker table tr td.active.highlighted:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74; }

.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
  color: #fff;
  background-color: #286090;
  border-color: #204d74; }

.datepicker table tr td.active:active:hover,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.highlighted.active.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40; }

.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.highlighted.disabled:hover,
.datepicker table tr td.active[disabled]:hover,
.datepicker table tr td.active.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.active:hover,
fieldset[disabled] .datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.highlighted.disabled:focus,
.datepicker table tr td.active[disabled]:focus,
.datepicker table tr td.active.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.active:focus,
fieldset[disabled] .datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.disabled.focus,
.datepicker table tr td.active.highlighted.disabled.focus,
.datepicker table tr td.active[disabled].focus,
.datepicker table tr td.active.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.active.focus,
fieldset[disabled] .datepicker table tr td.active.highlighted.focus {
  background-color: #337ab7;
  border-color: #2e6da4; }

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px; }

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: #eeeeee; }

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: default; }

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.focus,
.datepicker table tr td span.active:hover.focus,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active.disabled:hover.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40; }

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74; }

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  color: #fff;
  background-color: #286090;
  border-color: #204d74; }

.datepicker table tr td span.active:active:hover,
.datepicker table tr td span.active:hover:active:hover,
.datepicker table tr td span.active.disabled:active:hover,
.datepicker table tr td span.active.disabled:hover:active:hover,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active:hover.active:hover,
.datepicker table tr td span.active.disabled.active:hover,
.datepicker table tr td span.active.disabled:hover.active:hover,
.datepicker table tr td span.active:active:focus,
.datepicker table tr td span.active:hover:active:focus,
.datepicker table tr td span.active.disabled:active:focus,
.datepicker table tr td span.active.disabled:hover:active:focus,
.datepicker table tr td span.active.active:focus,
.datepicker table tr td span.active:hover.active:focus,
.datepicker table tr td span.active.disabled.active:focus,
.datepicker table tr td span.active.disabled:hover.active:focus,
.datepicker table tr td span.active:active.focus,
.datepicker table tr td span.active:hover:active.focus,
.datepicker table tr td span.active.disabled:active.focus,
.datepicker table tr td span.active.disabled:hover:active.focus,
.datepicker table tr td span.active.active.focus,
.datepicker table tr td span.active:hover.active.focus,
.datepicker table tr td span.active.disabled.active.focus,
.datepicker table tr td span.active.disabled:hover.active.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40; }

.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover.disabled:hover,
.datepicker table tr td span.active.disabled.disabled:hover,
.datepicker table tr td span.active.disabled:hover.disabled:hover,
.datepicker table tr td span.active[disabled]:hover,
.datepicker table tr td span.active:hover[disabled]:hover,
.datepicker table tr td span.active.disabled[disabled]:hover,
.datepicker table tr td span.active.disabled:hover[disabled]:hover,
fieldset[disabled] .datepicker table tr td span.active:hover,
fieldset[disabled] .datepicker table tr td span.active:hover:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active:hover.disabled:focus,
.datepicker table tr td span.active.disabled.disabled:focus,
.datepicker table tr td span.active.disabled:hover.disabled:focus,
.datepicker table tr td span.active[disabled]:focus,
.datepicker table tr td span.active:hover[disabled]:focus,
.datepicker table tr td span.active.disabled[disabled]:focus,
.datepicker table tr td span.active.disabled:hover[disabled]:focus,
fieldset[disabled] .datepicker table tr td span.active:focus,
fieldset[disabled] .datepicker table tr td span.active:hover:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active:hover.disabled.focus,
.datepicker table tr td span.active.disabled.disabled.focus,
.datepicker table tr td span.active.disabled:hover.disabled.focus,
.datepicker table tr td span.active[disabled].focus,
.datepicker table tr td span.active:hover[disabled].focus,
.datepicker table tr td span.active.disabled[disabled].focus,
.datepicker table tr td span.active.disabled:hover[disabled].focus,
fieldset[disabled] .datepicker table tr td span.active.focus,
fieldset[disabled] .datepicker table tr td span.active:hover.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover.focus {
  background-color: #337ab7;
  border-color: #2e6da4; }

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #777777; }

.datepicker .datepicker-switch {
  width: 145px; }

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer; }

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #eeeeee; }

.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden; }

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle; }

.input-group.date .input-group-addon {
  cursor: pointer; }

.input-daterange {
  width: 100%; }

.input-daterange input {
  text-align: center; }

.input-daterange input:first-child {
  border-radius: 3px 0 0 3px; }

.input-daterange input:last-child {
  border-radius: 0 3px 3px 0; }

.input-daterange .input-group-addon {
  width: auto;
  min-width: 16px;
  padding: 4px 5px;
  line-height: 1.42857143;
  border-width: 1px 0;
  margin-left: -5px;
  margin-right: -5px; }

/*# sourceMappingURL=bootstrap-datepicker3.css.map */
.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 120px;
  overflow: scroll;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all .15s linear;
  border: 2px solid #d9d9d9;
  border-radius: 4px; }

.dzu-dropzoneActive {
  background-color: #DEEBFF;
  border-color: #2484FF; }

.dzu-dropzoneDisabled {
  opacity: 0.5; }

.dzu-dropzoneDisabled *:hover {
  cursor: unset; }

.dzu-input {
  display: none; }

.dzu-inputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Helvetica', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #2484FF;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer; }

.dzu-inputLabelWithFiles {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 0 14px;
  min-height: 32px;
  background-color: #E6E6E6;
  color: #2484FF;
  border: none;
  font-family: 'Helvetica', sans-serif;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 3%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer; }

.dzu-previewContainer {
  padding: 40px 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 60px;
  z-index: 1;
  border-bottom: 1px solid #ECECEC;
  box-sizing: border-box; }

.dzu-previewStatusContainer {
  display: flex;
  align-items: center; }

.dzu-previewFileName {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333; }

.dzu-previewImage {
  width: auto;
  max-height: 40px;
  max-width: 140px;
  border-radius: 4px; }

.dzu-previewButton {
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.9;
  margin: 0 0 2px 10px; }

.dzu-submitButtonContainer {
  margin: 24px 0;
  z-index: 1; }

.dzu-submitButton {
  padding: 0 14px;
  min-height: 32px;
  background-color: #2484FF;
  border: none;
  border-radius: 4px;
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #FFF;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer; }

.dzu-submitButton:disabled {
  background-color: #E6E6E6;
  color: #333333;
  cursor: unset; }

.bg-main-blue {
  background-color: #006699; }
  .bg-main-blue.soft {
    background-color: rgba(0, 102, 153, 0.7); }

.bg-main-red {
  background-color: #E91D1D; }
  .bg-main-red.soft {
    background-color: rgba(233, 29, 29, 0.7); }

.bg-main-green {
  background-color: #32BB5B; }
  .bg-main-green.soft {
    background-color: rgba(50, 187, 91, 0.7); }

.bg-main-light-blue {
  background-color: #4095FD; }
  .bg-main-light-blue.soft {
    background-color: rgba(64, 149, 253, 0.7); }

.bg-main-pink {
  background-color: #DF4AC5; }
  .bg-main-pink.soft {
    background-color: rgba(223, 74, 197, 0.7); }

.bg-main-grey {
  background-color: #919291; }
  .bg-main-grey.soft {
    background-color: rgba(145, 146, 145, 0.7); }

.bg-main-orange {
  background-color: #D46400; }
  .bg-main-orange.soft {
    background-color: rgba(212, 100, 0, 0.7); }

/*
 * Navigation bar
 */
/*
 * Main content
 */
/*
 * Borders
 */
/*
* Bg colors
 */
.dzu-dropzone {
  overflow: auto;
  border: none;
  min-height: 0; }
  .dzu-dropzone .dzu-dropzone-wrapper {
    min-height: 100px;
    background: rgba(145, 146, 145, 0.2);
    border: dashed 2px #919291;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%; }
  .dzu-dropzone.dzu-dropzoneActive .dzu-dropzone-wrapper {
    border-color: #D46400;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); }

.dzu-inputLabel {
  margin-bottom: 0;
  color: #333333; }
  .dzu-inputLabel input[type="file"] {
    position: fixed;
    top: -1000px; }

.dzu-previewContainer {
  padding: 0;
  border: none; }

.dzu-previewImage {
  max-width: 100px; }

.dzu-previewStatusContainer progress {
  appearance: none;
  height: 10px; }
  .dzu-previewStatusContainer progress::-webkit-progress-bar {
    background: #919291; }
  .dzu-previewStatusContainer progress::-webkit-progress-value {
    background: #32BB5B; }
  .dzu-previewStatusContainer progress[value="100"] {
    display: none; }

@font-face {
  font-family: 'SINKIN Medium';
  src: url("../fonts/sinkinsans-500medium-webfont.eot");
  src: url("../fonts/sinkinsans-500medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/sinkinsans-500medium-webfont.woff") format("woff"), url("../fonts/sinkinsans-500medium-webfont.ttf") format("truetype"), url("../fonts/sinkinsans-500medium-webfont.svg#sinkin_sans500_medium") format("svg");
  font-weight: normal;
  font-style: normal; }

.bg-main-blue {
  background-color: #006699; }
  .bg-main-blue.soft {
    background-color: rgba(0, 102, 153, 0.7); }

.bg-main-red {
  background-color: #E91D1D; }
  .bg-main-red.soft {
    background-color: rgba(233, 29, 29, 0.7); }

.bg-main-green {
  background-color: #32BB5B; }
  .bg-main-green.soft {
    background-color: rgba(50, 187, 91, 0.7); }

.bg-main-light-blue {
  background-color: #4095FD; }
  .bg-main-light-blue.soft {
    background-color: rgba(64, 149, 253, 0.7); }

.bg-main-pink {
  background-color: #DF4AC5; }
  .bg-main-pink.soft {
    background-color: rgba(223, 74, 197, 0.7); }

.bg-main-grey {
  background-color: #919291; }
  .bg-main-grey.soft {
    background-color: rgba(145, 146, 145, 0.7); }

.bg-main-orange {
  background-color: #D46400; }
  .bg-main-orange.soft {
    background-color: rgba(212, 100, 0, 0.7); }

/*
 * Navigation bar
 */
/*
 * Main content
 */
/*
 * Borders
 */
/*
* Bg colors
 */
/*
* Hide the dotted lines around an element when it receives focus.
*/
* {
  _noFocusLine: expression(this.hideFocus=true); }

/* ie7 */
::-moz-focus-inner {
  border: 0; }

/* firefox */
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000; }

:focus, a:focus {
  outline: none; }

/* ie8, chrome, etc */
.r {
  background: url("../images/logo/mb_r.png") no-repeat top right;
  background-size: 25px 42px; }

.relative {
  position: relative; }

/*
 * Headers
 */
.h1-margin {
  margin-top: 34px;
  margin-bottom: 10px; }

h1, .h1 {
  color: #006699; }

h2, .h2, h3, .h3, h4, .h4 {
  color: rgba(0, 102, 153, 0.9); }

h1.align-tabs, h2.align-tabs, h3.align-tabs, h4.align-tabs, h5.align-tabs, h6.align-tabs {
  margin-top: -5px; }

/*
 * Messages
 */
ul.messages {
  margin-top: 15px; }

.confirmed-model h2, .confirmed-model h3, .confirmed-model h4 {
  margin-top: 10px; }

a.list-group-item:hover {
  background-color: rgba(64, 149, 253, 0.5);
  color: white; }

.eye-catcher {
  background-color: #4095FD;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-top: solid 2px #919291;
  border-bottom: solid 2px #919291; }
  .eye-catcher h1 small, .eye-catcher h2 small, .eye-catcher h3 small, .eye-catcher h4 small, .eye-catcher h5 small, .eye-catcher h6 small {
    color: rgba(255, 255, 255, 0.4); }
  .eye-catcher h1, .eye-catcher .h1 {
    color: rgba(255, 255, 255, 0.95); }
  .eye-catcher a {
    color: #006699;
    font-weight: bold; }
    .eye-catcher a.btn {
      color: #FFFFFF; }

p {
  margin-top: 10px; }

.feedback p {
  background-color: rgba(223, 74, 197, 0.4);
  margin-left: -5px;
  margin-right: -5px;
  padding: 5px; }

body > footer {
  margin-top: 50px; }

.popover {
  background-color: #32BB5B;
  color: #FFFFFF;
  text-shadow: 1px 1px 0 #227f3e; }
  .popover.top > .arrow:after {
    border-top-color: #32BB5B; }
  .popover.bottom > .arrow:after {
    border-bottom-color: #32BB5B; }
  .popover.left > .arrow:after {
    border-left-color: #32BB5B; }
  .popover.right > .arrow:after {
    border-right-color: #32BB5B; }

.inline {
  display: inline; }

a.thumbnail + ul.list-inline {
  position: absolute;
  top: 6px;
  right: 21px;
  background: rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px; }
  a.thumbnail + ul.list-inline:hover {
    background: rgba(0, 0, 0, 0.7);
    background: rgba(0, 0, 0, 0.7);
    background: rgba(0, 0, 0, 0.7);
    background: rgba(0, 0, 0, 0.7);
    background: rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3); }
  a.thumbnail + ul.list-inline > li {
    padding-left: 0;
    padding-right: 0; }
    a.thumbnail + ul.list-inline > li:first-child {
      padding-left: 2px; }
    a.thumbnail + ul.list-inline > li:last-child {
      padding-right: 2px; }
  a.thumbnail + ul.list-inline a {
    color: #FFFFFF; }

.error__logo {
  display: block;
  height: 148px;
  background: url("../images/logo/logo_spiegel.jpg") no-repeat;
  background-position: center bottom; }
  .error__logo:hover {
    cursor: pointer; }

.error__code {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  color: #006699;
  font-size: 1.8em;
  font-weight: bold;
  margin-top: -1.2em; }

.error__text {
  max-width: 600px;
  margin: 0 auto; }

.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg {
  text-align: left; }

.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg {
  text-align: center; }

.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg {
  text-align: right; }

.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg {
  text-align: justify; }

@media (max-width: 767px) {
  .text-left-not-xs, .text-center-not-xs, .text-right-not-xs, .text-justify-not-xs {
    text-align: inherit; }
  .text-left-xs {
    text-align: left; }
  .text-center-xs {
    text-align: center; }
  .text-right-xs {
    text-align: right; }
  .text-justify-xs {
    text-align: justify; } }

@media (min-width: 768px) and (max-width: 991px) {
  .text-left-not-sm, .text-center-not-sm, .text-right-not-sm, .text-justify-not-sm {
    text-align: inherit; }
  .text-left-sm {
    text-align: left; }
  .text-center-sm {
    text-align: center; }
  .text-right-sm {
    text-align: right; }
  .text-justify-sm {
    text-align: justify; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .text-left-not-md, .text-center-not-md, .text-right-not-md, .text-justify-not-md {
    text-align: inherit; }
  .text-left-md {
    text-align: left; }
  .text-center-md {
    text-align: center; }
  .text-right-md {
    text-align: right; }
  .text-justify-md {
    text-align: justify; } }

@media (min-width: 1200px) {
  .text-left-not-lg, .text-center-not-lg, .text-right-not-lg, .text-justify-not-lg {
    text-align: inherit; }
  .text-left-lg {
    text-align: left; }
  .text-center-lg {
    text-align: center; }
  .text-right-lg {
    text-align: right; }
  .text-justify-lg {
    text-align: justify; } }

@media (max-width: 480px) {
  * + .pull-right {
    float: none !important;
    padding-top: 5px; } }

.navbar-center .navbar-nav {
  display: inline-block;
  float: none; }

.navbar-center .navbar-collapse {
  text-align: center; }

#main-nav li.tab {
  font-family: "SINKIN Medium", sans-serif; }

body.fade-small-logo .navbar-brand img {
  filter: gray;
  -webkit-filter: grayscale(100%);
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7; }

@media (min-width: 768px) {
  .navbar-brand {
    position: absolute;
    padding: 0;
    top: 0;
    left: 30px; }
    .navbar-brand img {
      height: 35px;
      width: 184px; }
  .navbar-collapse.collapse {
    height: 35px !important; }
  .sprue-tabs {
    background: -owg-linear-gradient(180deg, #ececec, #f8f8f8);
    background: -webkit-linear-gradient(180deg, #ececec, #f8f8f8);
    background: -moz-linear-gradient(180deg, #ececec, #f8f8f8);
    background: -o-linear-gradient(180deg, #ececec, #f8f8f8);
    background: linear-gradient(180deg, #ececec, #f8f8f8);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    min-height: 35px;
    max-height: 35px;
    margin: 0 -15px; }
    .sprue-tabs li.tab > a {
      color: #f4f4f4;
      background-color: #e5e5e5;
      display: block;
      padding: 3px 20px 6px;
      margin: 5px 10px 0;
      font-size: 115%;
      -moz-border-radius-topleft: 10px;
      -webkit-border-top-left-radius: 10px;
      border-top-left-radius: 10px;
      -moz-border-radius-topright: 10px;
      -webkit-border-top-right-radius: 10px;
      border-top-right-radius: 10px;
      text-shadow: #AAA 1px 1px 3px, #AAA 1px 1px 1px, #BBB 1px 1px 0, #BBB 0 0 1px;
      border: solid 1px #c8c8c8;
      border-bottom: none;
      -webkit-box-shadow: #AAA 0 -1px 1px -1px, 3px 0 4px rgba(0, 0, 0, 0.1), inset rgba(255, 255, 255, 0.5) 0 2px 1px;
      -moz-box-shadow: #AAA 0 -1px 1px -1px, 3px 0 4px rgba(0, 0, 0, 0.1), inset rgba(255, 255, 255, 0.5) 0 2px 1px;
      box-shadow: #AAA 0 -1px 1px -1px, 3px 0 4px rgba(0, 0, 0, 0.1), inset rgba(255, 255, 255, 0.5) 0 2px 1px;
      overflow: hidden; }
      .sprue-tabs li.tab > a:hover, .sprue-tabs li.tab > a:focus, .sprue-tabs li.tab > a:active, .sprue-tabs li.tab > a.active {
        background-color: #fafafa;
        color: #fafafa;
        padding-top: 5px;
        margin-top: 3px; } }

@media (max-width: 768px) {
  .navbar-brand {
    height: 50px;
    padding: 7.5px 15px; }
    .navbar-brand img {
      height: 35px;
      width: 184px; }
  .navbar-center .navbar-collapse {
    text-align: left;
    padding: 0; }
  .navbar-center .navbar-nav {
    width: 100%;
    margin: 0; }
  li.tab {
    border: solid 1px #e5e5e5;
    background: -owg-linear-gradient(180deg, #ececec, #f8f8f8);
    background: -webkit-linear-gradient(180deg, #ececec, #f8f8f8);
    background: -moz-linear-gradient(180deg, #ececec, #f8f8f8);
    background: -o-linear-gradient(180deg, #ececec, #f8f8f8);
    background: linear-gradient(180deg, #ececec, #f8f8f8); }
    li.tab:hover, li.tab.active, li.tab:focus, li.tab:active {
      background: -owg-linear-gradient(0deg, #ececec, #f8f8f8);
      background: -webkit-linear-gradient(0deg, #ececec, #f8f8f8);
      background: -moz-linear-gradient(0deg, #ececec, #f8f8f8);
      background: -o-linear-gradient(0deg, #ececec, #f8f8f8);
      background: linear-gradient(0deg, #ececec, #f8f8f8); } }

/*
 * Shop banner
 */
@media (min-width: 650px) {
  #shop-banner {
    text-align: center;
    padding: 5px 15px 0;
    -webkit-box-shadow: inset #c8c8c8 0 5px 5px -5px;
    -moz-box-shadow: inset #c8c8c8 0 5px 5px -5px;
    box-shadow: inset #c8c8c8 0 5px 5px -5px;
    margin: 0 -15px;
    font-size: 0; }
    #shop-banner li {
      display: inline-block; }
      #shop-banner li a {
        display: block;
        height: 25px;
        background-image: url("../images/shop/products_sprite.png");
        background-repeat: no-repeat; } }

@media (max-width: 650px) {
  #shop-banner {
    display: none; } }

/* brands, individual */
#shop-uhu {
  width: 54px;
  background-position: 0px -293px; }

#shop-xuron {
  width: 102px;
  background-position: -54px -293px; }

#shop-revell {
  width: 62px;
  background-position: -156px -293px; }

#shop-alclad {
  width: 65px;
  background-position: -218px -293px; }

#shop-bmf {
  width: 66px;
  background-position: -283px -293px; }

#shop-birchwood {
  width: 52px;
  background-position: -349px -293px; }

#shop-evergreen {
  width: 50px;
  background-position: -516px -318px; }

#shop-sp {
  width: 74px;
  background-position: -442px -318px; }

#shop-excel {
  width: 56px;
  background-position: -442px -293px; }

#shop-intech {
  width: 86px;
  background-position: -498px -293px; }

#shop-microscale {
  width: 62px;
  background-position: 0 -318px; }

#shop-miliput {
  width: 93px;
  background-position: -62px -318px; }

#shop-mb {
  width: 127px;
  background-position: -155px -318px; }

#shop-rustall {
  width: 93px;
  background-position: -282px -318px; }

#shop-sms {
  width: 25px;
  background-position: -375px -318px; }

#shop-vallejo {
  width: 42px;
  background-position: -400px -318px; }

header .dropdown-toggle {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  header .dropdown-toggle:hover, header .dropdown-toggle:focus, header .dropdown-toggle:active {
    background-color: #0088cc;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
    color: #FFFFFF; }

header .dropdown-menu {
  top: auto;
  right: 0;
  left: auto;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background-color: #006699;
  padding: 0; }
  header .dropdown-menu > li > a {
    color: #FFFFFF;
    font-weight: bold;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }
    header .dropdown-menu > li > a:hover, header .dropdown-menu > li > a:focus, header .dropdown-menu > li > a:active {
      background-color: #0088cc;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
      color: #FFFFFF; }

.nav-pills {
  margin-top: 5px;
  margin-bottom: 5px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8; }

.nav > li > a {
  padding: 5px 15px; }

label {
  font-weight: 500; }
  label.required {
    font-weight: 700; }
    label.required:after {
      content: "*"; }

textarea.form-control {
  min-height: 100px; }

li.error {
  color: red;
  font-weight: bolder; }

form .submit-wrapper {
  text-align: right;
  margin-bottom: 30px; }

form fieldset.well h3 {
  margin-top: 0; }

form fieldset.tab-pane {
  border: solid 1px #DDD;
  border-top: none;
  padding: 15px;
  margin-bottom: 15px; }

/* buttons */
.btn {
  color: #FFFFFF; }
  .btn:hover, .btn:active, .btn:focus {
    color: #FFFFFF;
    text-decoration: underline; }
  .btn.btn-default {
    background-color: #919291; }
  .btn.fade {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease; }
    .btn.fade:hover, .btn.fade:focus {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }

.bootstrap-select .btn {
  color: #333333; }
  .bootstrap-select .btn.btn-default {
    background-color: transparent; }
    .bootstrap-select .btn.btn-default:hover {
      background-color: inherit;
      color: inherit;
      text-decoration: none; }

.formset-form {
  position: relative; }
  .formset-form .DELETE .checkbox {
    position: static; }
  .formset-form .DELETE input {
    display: none; }
  .formset-form .DELETE label {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 15; }
    .formset-form .DELETE label:hover, .formset-form .DELETE label:focus {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
  .formset-form .DELETE .delete-backdrop {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.75); }
  .formset-form .DELETE input:checked + label {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
    .formset-form .DELETE input:checked + label + .delete-backdrop {
      display: block; }
  .formset-form.delete > * {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
    opacity: 0.1; }

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
  width: 85%;
  float: left; }
  input[type="range"]::-webkit-slider-thumb {
    margin-top: -7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-transition: background 0.5s ease;
    -moz-transition: background 0.5s ease;
    -o-transition: background 0.5s ease;
    transition: background 0.5s ease;
    border: 1px solid #D46400;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    background: #ffffff;
    cursor: pointer; }
  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-transition: background 0.5s ease;
    -moz-transition: background 0.5s ease;
    -o-transition: background 0.5s ease;
    transition: background 0.5s ease;
    border: 1px solid #D46400;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    background: #ffffff;
    cursor: pointer; }
  input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-transition: background 0.5s ease;
    -moz-transition: background 0.5s ease;
    -o-transition: background 0.5s ease;
    transition: background 0.5s ease;
    border: 1px solid #D46400;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    background: #ffffff;
    cursor: pointer; }
  input[type="range"]:focus, input[type="range"]:active, input[type="range"]:hover {
    outline: none;
    border: none;
    box-shadow: none; }
    input[type="range"]:focus::-webkit-slider-thumb, input[type="range"]:active::-webkit-slider-thumb, input[type="range"]:hover::-webkit-slider-thumb {
      margin-top: -7px;
      background: #ffe8d4; }
    input[type="range"]:focus::-moz-range-thumb, input[type="range"]:active::-moz-range-thumb, input[type="range"]:hover::-moz-range-thumb {
      background: #ffe8d4; }
    input[type="range"]:focus::-ms-thumb, input[type="range"]:active::-ms-thumb, input[type="range"]:hover::-ms-thumb {
      background: #ffe8d4; }
  input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent; }
  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    width: 100%;
    height: 6px;
    cursor: pointer;
    border: solid 1px #006699;
    background-color: #4095FD; }
  input[type="range"]::-moz-range-track {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    width: 100%;
    height: 6px;
    cursor: pointer;
    border: solid 1px #006699;
    background-color: #4095FD; }
  input[type="range"]::-ms-track {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    width: 100%;
    height: 6px;
    cursor: pointer;
    border: solid 1px #006699;
    background-color: #4095FD; }
  input[type="range"] + output {
    width: 15%;
    float: left;
    text-align: center; }

.ad-container {
  position: relative; }

#ads {
  position: absolute;
  top: 0;
  right: 0; }
  @media (max-width: 1340px) {
    #ads {
      z-index: -1; } }
  @media (min-width: 1600px) {
    #ads {
      right: 30px; } }
  #ads a {
    display: block;
    width: 90px;
    height: 400px;
    background-color: rgba(0, 0, 0, 0.01);
    margin-bottom: 15px;
    border: dotted 1px #EEE; }

.pagination > .active > a {
  background-color: #32BB5B;
  border-color: #32BB5B;
  color: #FFFFFF; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px; }
  .pagination > li {
    display: inline; }
    .pagination > li > a,
    .pagination > li > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #337ab7;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd; }
    .pagination > li:first-child > a,
    .pagination > li:first-child > span {
      margin-left: 0;
      -moz-border-radius-topleft: 4px;
      -webkit-border-top-left-radius: 4px;
      border-top-left-radius: 4px;
      -moz-border-radius-bottomleft: 4px;
      -webkit-border-bottom-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      -moz-border-radius-topright: 4px;
      -webkit-border-top-right-radius: 4px;
      border-top-right-radius: 4px;
      -moz-border-radius-bottomright: 4px;
      -webkit-border-bottom-right-radius: 4px;
      border-bottom-right-radius: 4px; }
  .pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover,
  .pagination > .disabled > span,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > span:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd; }

@-webkit-keyframes change-color {
  0% {
    color: #E91D1D; }
  20% {
    color: #32BB5B; }
  40% {
    color: #4095FD; }
  60% {
    color: #DF4AC5; }
  80% {
    color: #919291; }
  100% {
    color: #006699; } }

@keyframes change-color {
  0% {
    color: #E91D1D; }
  20% {
    color: #32BB5B; }
  40% {
    color: #4095FD; }
  60% {
    color: #DF4AC5; }
  80% {
    color: #919291; }
  100% {
    color: #006699; } }

/* Lightbox */
.lightbox .modal-dialog {
  width: 75vw;
  padding: 0; }

.lightbox .modal-content {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  height: 90vh;
  margin-top: 5vh;
  margin-bottom: 5vh; }

.lightbox .modal-content > * {
  padding: 0;
  border: none;
  max-height: 100%; }

.lightbox .fa-spinner {
  line-height: 90vh;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=75);
  opacity: 0.75;
  -webkit-animation: fa-spin 1s infinite steps(8), change-color 1s infinite steps(6);
  animation: fa-spin 1s infinite steps(8), change-color 1s infinite steps(6); }

.lightbox .carousel {
  background-color: rgba(0, 0, 0, 0.95);
  padding: 2.5vh 0; }

.lightbox .item {
  text-align: center;
  height: 85vh; }
  .lightbox .item img {
    max-height: 100%;
    margin: 0 auto;
    padding: 5px; }

.lightbox .carousel-control {
  line-height: 90vh; }

.tt-menu {
  background-color: white;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px; }
  .tt-menu .tt-suggestion {
    padding: 2px 10px; }
    .tt-menu .tt-suggestion:hover, .tt-menu .tt-suggestion.tt-cursor {
      background-color: rgba(145, 146, 145, 0.25);
      cursor: pointer; }

.twitter-typeahead {
  width: 100%; }

.button {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid transparent;
  display: inline-block;
  text-align: center;
  padding: 6px 12px;
  font-weight: bold; }
  .button:hover, .button:active, .button:focus {
    cursor: pointer;
    text-decoration: underline; }
  .button--icon .fa {
    margin-right: 0.5em; }
  .button--full {
    display: block;
    min-width: 100%; }
  .button--disabled:hover {
    cursor: default; }
  .button--blue {
    background-color: #006699; }
    .button--blue, .button--blue:hover, .button--blue:active, .button--blue:focus {
      color: #FFFFFF; }
  .button--orange {
    background-color: #D46400; }
    .button--orange, .button--orange:hover, .button--orange:active, .button--orange:focus {
      color: #FFFFFF; }
  .button--light-blue {
    background-color: #4095FD; }
    .button--light-blue, .button--light-blue:hover, .button--light-blue:active, .button--light-blue:focus {
      color: #FFFFFF; }
  .button--fade {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    font-weight: normal; }
    .button--fade:hover, .button--fade:active, .button--fade:focus {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
  .button__icon {
    background: #D46400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-appearance: none;
    border: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px; }
    .button__icon:hover {
      background: #a14c00; }
  .button--order {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -ms-border-radius: 0 4px 4px 0;
    -o-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0; }
  .button__add {
    width: 100%; }

.button-row {
  padding: 5px 0;
  display: flex;
  align-items: center; }
  .button-row__text {
    font-size: 10px;
    margin: 0 0 0 5px;
    line-height: 11px; }

.button-group {
  padding: 1em;
  text-align: center; }

.radio-select {
  list-style-type: none;
  padding-left: 0; }
  .radio-select label {
    margin-bottom: 0; }

.qq-uploader .qq-hide {
  display: none; }

.qq-uploader .qq-uploader__drop-area {
  position: static;
  min-height: 100px;
  background: rgba(145, 146, 145, 0.2);
  border: dashed 2px #919291;
  display: flex;
  align-items: center;
  justify-content: center; }
  .qq-uploader .qq-uploader__drop-area.qq-uploader__drop-area--upload-complete {
    display: none; }

.qq-uploader .qq-uploader__progress-bar {
  height: 10px;
  background: #32BB5B; }

.qq-uploader .qq-uploader__upload-button-container {
  display: inline-block; }

.qq-uploader .qq-uploader__upload-button {
  display: inline-block; }

.qq-uploader .qq-uploader__upload-list {
  padding: 0;
  margin: 1em 0 0; }
  .qq-uploader .qq-uploader__upload-list.qq-uploader__upload-list--upload-complete {
    margin-top: 0; }

.qq-uploader .qq-uploader__upload-list-item {
  list-style: none; }

.qq-uploader .qq-uploader__preview {
  display: table; }

.qq-uploader .qq-uploader__upload-actions {
  display: table;
  text-align: right; }

.qq-uploader .qq-uploader__upload-list-item.qq-upload-fail .qq-uploader__status-text {
  color: #E91D1D;
  font-weight: 600; }

.qq-uploader .qq-btn {
  padding: 3px 8px; }

.kit .kit__header {
  border-bottom: solid 3px rgba(64, 149, 253, 0.5);
  padding-bottom: 0.5em;
  margin-bottom: 2em; }

.kit .kit__name {
  text-transform: uppercase;
  margin-top: 0; }

.kit .kit__brand-logo,
.kit .kit__brand-name {
  float: left;
  margin-right: 1em; }

.kit .kit__brand-name {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.4em;
  color: #919291;
  line-height: 2.5em; }

.kit .kit__boxart {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  text-align: center; }
  .kit .kit__boxart:hover, .kit .kit__boxart:target {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }

.kit .kit__boxart-image {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  margin: 0 auto;
  border: solid 1px #CCC;
  padding: 4px;
  max-width: 100%; }

.kit-preview {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px #CCC;
  padding: 1em 1em 0;
  margin-bottom: 30px;
  overflow: hidden; }
  .kit-preview .button--full {
    margin-left: -15px;
    margin-right: -15px; }
  .kit-preview .kit-preview__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    margin-top: 0; }
  .kit-preview .kit-preview__image {
    text-align: center; }

.find-kit-form .find-kit-form__button-search {
  margin-right: 1em; }

.find-kit-form .find-kit-form__button-add-kit {
  margin-left: 1em; }

@media (max-width: 768px) {
  .find-kit-form .find-kit-form__button-search,
  .find-kit-form .find-kit-form__button-add-kit {
    display: block;
    margin-left: auto;
    margin-right: auto; } }

.review-list {
  padding-top: 1em; }

.review-preview {
  border: solid 1px #CCC;
  padding: 15px; }
  .review-preview.review-preview--with-ratings .review-preview__body {
    padding-top: 2em;
    max-height: 200px; }
  .review-preview + .review-preview {
    margin-top: 15px; }
  .review-preview .review-preview__title {
    font-size: 18px;
    margin: 0; }
  .review-preview .review-preview__details {
    color: #919291; }
  .review-preview .review-preview__rating {
    margin-left: 1em;
    color: #32BB5B; }
  .review-preview .review-preview__body {
    max-height: 100px;
    position: relative;
    min-height: 50px;
    overflow: hidden;
    padding-top: 1em; }
    .review-preview .review-preview__body:after {
      content: " ";
      position: absolute;
      width: 100%;
      display: block;
      height: 50px;
      bottom: 0;
      left: 0;
      background: -owg-linear-gradient(180deg, transparent, #FFFFFF);
      background: -webkit-linear-gradient(180deg, transparent, #FFFFFF);
      background: -moz-linear-gradient(180deg, transparent, #FFFFFF);
      background: -o-linear-gradient(180deg, transparent, #FFFFFF);
      background: linear-gradient(180deg, transparent, #FFFFFF); }
  @media (max-width: 768px) {
    .review-preview .review-preview__rating {
      display: block;
      margin-left: 0;
      margin-top: 0.5em; }
    .review-preview .button + .button {
      margin-bottom: 0.5em; } }
  @media (max-width: 992px) {
    .review-preview .review-preview__details {
      text-align: left; } }

.review-ratings {
  padding-top: 2em; }
  .review-ratings.review-ratings--in-preview {
    max-height: 200px;
    position: relative;
    min-height: 50px;
    overflow: hidden; }
    .review-ratings.review-ratings--in-preview:after {
      content: " ";
      position: absolute;
      width: 100%;
      display: block;
      height: 50px;
      bottom: 0;
      left: 0;
      background: -owg-linear-gradient(180deg, transparent, #FFFFFF);
      background: -webkit-linear-gradient(180deg, transparent, #FFFFFF);
      background: -moz-linear-gradient(180deg, transparent, #FFFFFF);
      background: -o-linear-gradient(180deg, transparent, #FFFFFF);
      background: linear-gradient(180deg, transparent, #FFFFFF); }
  .review-ratings .review-ratings__item {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=85);
    opacity: 0.85;
    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease; }
    .review-ratings .review-ratings__item:hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
    .review-ratings .review-ratings__item .progress {
      margin-bottom: 15px; }
  @media (max-width: 768px) {
    .review-ratings .review-rating__label {
      text-align: left; } }

.review .review__body {
  margin-top: 1em;
  font-size: 1.14em;
  text-align: justify; }

.category__search-container {
  margin: 1rem auto; }

.category__carousel, .category__carousel-item, .category__inner {
  height: 300px; }

.category__link {
  color: #D46400; }

.category__title {
  color: #333333;
  margin: 20px auto; }

.category__content {
  width: calc(100% - 220px); }

.subcategories {
  display: flex;
  flex-flow: wrap; }
  .subcategories__wrapper {
    padding: 10px;
    border: 1px solid #DBDEE1;
    border-radius: 10px;
    width: 100%; }
  .subcategories__header {
    color: #333333; }

.subcategory__card {
  display: flex;
  flex-direction: column;
  width: 150px;
  padding: 20px; }

.subcategory__img {
  height: 100px;
  width: 100px;
  border: 1px solid #EEE;
  border-radius: 5px; }

.subcategory__name {
  margin-top: 5px; }

.category-sidenav {
  list-style: none;
  width: 200px;
  border: 1px solid #DBDEE1;
  border-radius: 5px;
  padding: 10px 10px 0;
  margin-right: 30px;
  height: 100%; }
  .category-sidenav__header {
    font-size: 14px; }
  .category-sidenav__item:not(:last-child) {
    border-bottom: 1px solid #EEE; }
  .category-sidenav__link {
    font-size: 12px;
    color: #333333;
    display: block;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px; }
    .category-sidenav__link:hover {
      text-decoration: none; }
    .category-sidenav__link--active {
      font-weight: bold; }
  .category-sidenav__header {
    font-weight: bold; }
  .category-sidenav__subcategories {
    font-size: 12px;
    padding-left: 10px; }
  .category-sidenav__subcategory--active {
    font-weight: bold; }

.list {
  list-style: none; }

.wrapper {
  display: flex;
  padding: 20px; }

.breadcrumbs {
  padding: 5px 0; }
  .breadcrumbs__separator {
    color: #919291; }

.product-card {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 150px;
  border: 1px solid #DBDEE1;
  padding: 5px;
  margin: 0 15px 20px 0; }
  .product-card__image {
    width: 100%;
    flex: 1 0 auto; }
  .product-card__name {
    color: #333333;
    padding: 5px 0;
    flex: 1 0 auto;
    font-size: 12px; }
  .product-card__price {
    font-weight: bold;
    margin: 5px 0;
    flex: 0; }

.products {
  width: 100%; }

.product-list {
  display: flex;
  flex-flow: wrap; }

.product__image {
  width: 100%; }

.product__image-wrapper {
  width: 250px;
  height: 250px; }

.product__name {
  color: #333333;
  margin: 0; }

.product__inner {
  display: flex;
  padding: 30px 0;
  flex-flow: wrap; }

.product__brand-logo {
  height: 50px; }

.product__content, .product__inner {
  width: 100%; }

.product__image-container, .product__info {
  width: 50%; }

.product__amount-label {
  display: none; }

.product__amount {
  width: 50px;
  padding: 0 5px;
  border: 1px solid #006699;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px; }
  .product__amount::-webkit-inner-spin-button, .product__amount::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.product__order {
  display: flex;
  padding: 15px 0; }

.product__brand {
  margin: 0; }

.product__brand-link {
  color: #919291;
  text-transform: uppercase; }

.product__text-group {
  display: flex;
  flex-direction: column;
  padding: 5px 0; }

.product__label {
  font-size: 12px;
  color: #919291; }

.product__text {
  font-size: 14px;
  font-weight: bold; }
  .product__text--lg {
    font-size: 16px;
    font-weight: bold; }

.product__tabs {
  width: 100%;
  margin-top: 30px; }
  .product__tabs .nav-tabs {
    border-bottom: none; }

.product__tab-content {
  padding: 10px 20px;
  border: 1px solid #ddd;
  -webkit-border-radius: 0 4px 4px 4px;
  -moz-border-radius: 0 4px 4px 4px;
  -ms-border-radius: 0 4px 4px 4px;
  -o-border-radius: 0 4px 4px 4px;
  border-radius: 0 4px 4px 4px; }

.product__actions {
  display: flex; }
  .product__actions .button-row:first-child {
    margin-right: 30px; }

.product__review-item:not(:last-child) {
  padding: 10px 0;
  border-bottom: 1px solid #DBDEE1; }

.product__leave-review {
  margin-top: 40px;
  color: #333333; }

.product__rating {
  display: flex;
  align-items: center;
  padding: 5px 0; }
  .product__rating .fa {
    font-size: 18px;
    color: #D46400; }

.product__rating-info {
  margin-left: 10px; }

.review__info {
  display: flex;
  width: 100%;
  justify-content: space-between; }

.review__reviewer-name {
  font-weight: bold;
  margin-right: 10px; }

.review__date {
  font-size: 12px;
  color: #919291; }

.rating-input {
  display: flex;
  justify-content: flex-end;
  unicode-bidi: bidi-override;
  direction: rtl; }
  .rating-input__input, .rating-input__label {
    visibility: hidden;
    opacity: 0; }
  .rating-input__option:hover .rating-input__star, .rating-input__option--is-active .rating-input__star, .rating-input__option:hover ~ .rating-input__option .rating-input__star, .rating-input__option--is-active ~ .rating-input__option .rating-input__star {
    color: transparent; }
  .rating-input__option:hover .rating-input__star::before, .rating-input__option--is-active .rating-input__star::before, .rating-input__option:hover ~ .rating-input__option .rating-input__star::before, .rating-input__option--is-active ~ .rating-input__option .rating-input__star::before {
    content: "\2605";
    position: absolute;
    color: #D46400; }
  .rating-input__star {
    font-size: 24px;
    cursor: pointer; }

.cart {
  padding: 20px; }
  .cart__row {
    display: flex;
    justify-content: flex-end;
    position: relative; }
  .cart__box {
    border: 1px solid #EEE;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: flex-end;
    flex-direction: column; }
    .cart__box:hover {
      cursor: pointer; }
  .cart__inner {
    display: flex;
    align-items: center; }
  .cart__info {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    margin-left: 20px; }
  .cart__price {
    font-weight: bold; }
  .cart__icon {
    color: #006699;
    font-size: 24px; }
  .cart__actions {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid #EEE; }
    .cart__actions .button {
      font-size: 12px; }
  .cart__container {
    transition: all .1s linear; }
    .cart__container--expanded .cart__menu {
      opacity: 1;
      visibility: visible;
      animation-delay: 5s;
      transition: all .3s linear; }
    .cart__container--expanded .cart__box {
      -webkit-border-radius: 6px 6px 0 0;
      -moz-border-radius: 6px 6px 0 0;
      -ms-border-radius: 6px 6px 0 0;
      -o-border-radius: 6px 6px 0 0;
      border-radius: 6px 6px 0 0;
      margin-bottom: -1px; }
  .cart__menu {
    opacity: 0;
    visibility: hidden;
    border: 1px solid #EEE;
    -webkit-box-shadow: 0 2px 8px 0 rgba(186, 198, 208, 0.5), 0 2px 8px 0 rgba(0, 157, 255, 0.05);
    -moz-box-shadow: 0 2px 8px 0 rgba(186, 198, 208, 0.5), 0 2px 8px 0 rgba(0, 157, 255, 0.05);
    box-shadow: 0 2px 8px 0 rgba(186, 198, 208, 0.5), 0 2px 8px 0 rgba(0, 157, 255, 0.05);
    overflow: hidden;
    position: absolute;
    right: 0;
    background: #FFFFFF;
    width: 380px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    -ms-border-radius: 6px 0 6px 6px;
    -o-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px; }
  .cart__products {
    overflow-y: scroll;
    max-height: 300px; }

.controls__row {
  display: flex;
  justify-content: space-between; }

.controls__amount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  text-align: center; }

.cart-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  text-align: center; }
  .cart-product:not(last-child) {
    border-bottom: 1px solid #EEE; }
  .cart-product__image {
    width: 15%; }
    .cart-product__image img {
      width: 40px;
      height: 40px; }
  .cart-product__name {
    font-size: 12px;
    width: 45%;
    padding: 0 5px; }
  .cart-product__amount {
    width: 10%; }
  .cart-product__price {
    width: 25%; }
  .cart-product__remove {
    cursor: pointer;
    width: 5%; }

.cart-totals {
  float: right;
  padding: 20px;
  width: 250px; }
  .cart-totals__row {
    display: flex;
    justify-content: space-between;
    line-height: 2; }
  .cart-totals__text {
    margin-right: 10px; }
  .cart-totals__value {
    font-weight: bold; }

.cart-detail__page-header {
  margin-bottom: 20px; }

.cart-detail__table {
  width: 100%;
  border: 1px solid #EEE; }

.cart-detail__thead {
  background: #EEEEEE; }
  .cart-detail__thead th {
    padding: 15px; }

.cart-detail__tbody tr:not(last-child) {
  border-bottom: 1px solid #EEE; }

.cart-detail__tbody td {
  padding: 0 10px; }

.cart-detail__image {
  width: 50px;
  height: 50px; }

.cart-detail__quantity {
  width: 100px;
  padding-right: 20px !important; }

.loader__container {
  padding: 5px; }

.album-preview {
  position: relative; }
  .album-preview h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .album-preview a.thumbnail + ul.list-inline {
    top: 62px; }

body.albums header .dropdown-toggle {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  body.albums header .dropdown-toggle:hover, body.albums header .dropdown-toggle:focus, body.albums header .dropdown-toggle:active {
    background-color: #0088cc;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
    color: #FFFFFF; }

body.albums header .dropdown-menu {
  top: auto;
  right: 0;
  left: auto;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background-color: #006699;
  padding: 0; }
  body.albums header .dropdown-menu > li > a {
    color: #FFFFFF;
    font-weight: bold;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }
    body.albums header .dropdown-menu > li > a:hover, body.albums header .dropdown-menu > li > a:focus, body.albums header .dropdown-menu > li > a:active {
      background-color: #0088cc;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
      color: #FFFFFF; }

body.albums .cover {
  border-color: #006699;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3); }
  body.albums .cover + ul .set-cover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
    opacity: 0.2; }

body.albums .qq-upload-button {
  width: auto;
  display: inline-block;
  padding: 6px; }

body.albums #trigger-upload {
  font-weight: bold; }

body.albums .qq-upload-drop-area {
  position: static;
  min-height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: dashed 2px #919291;
  background: rgba(145, 146, 145, 0.2); }
  body.albums .qq-upload-drop-area > span {
    text-transform: uppercase;
    font-weight: bolder;
    color: rgba(145, 146, 145, 0.8);
    position: static;
    margin-top: 0;
    line-height: 100px; }
  body.albums .qq-upload-drop-area.qq-upload-drop-area-active {
    border-color: #D46400; }

body.albums .qq-upload-list {
  margin-top: 10px; }

body.albums .photo-count {
  left: 20px;
  font-size: 65%;
  background-color: rgba(50, 187, 91, 0.9);
  line-height: 1;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  padding: 4px 12px;
  width: auto; }

body.albums .stats {
  color: rgba(255, 255, 255, 0.5); }
  body.albums .stats + .controls,
  body.albums .stats + div {
    margin-top: 30px; }

body.albums .pre-content {
  margin-bottom: 20px; }
  body.albums .pre-content .pagination {
    margin-top: 0;
    margin-bottom: 0; }

body.albums.modal-open .modal-backdrop.in {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=75);
  opacity: 0.75; }

body.albums #modal-lightbox .carousel-control {
  max-width: 75px; }

body.albums.photo-detail header {
  margin-bottom: 10px; }
  body.albums.photo-detail header h1 {
    font-size: 24px; }
  body.albums.photo-detail header a.pull-right {
    margin-top: 20px; }

body.albums.photo-detail .photo figure {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.25);
  position: relative;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
  -webkit-transition: box-shadow 1s ease;
  -moz-transition: box-shadow 1s ease;
  -o-transition: box-shadow 1s ease;
  transition: box-shadow 1s ease; }
  body.albums.photo-detail .photo figure:hover, body.albums.photo-detail .photo figure:focus, body.albums.photo-detail .photo figure:active {
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4), 0 10px 14px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4), 0 10px 14px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4), 0 10px 14px 0 rgba(0, 0, 0, 0.3); }
  body.albums.photo-detail .photo figure img {
    margin: 0 auto; }
  body.albums.photo-detail .photo figure figcaption {
    margin: 10px auto 0;
    width: 90%; }

body.albums.photo-detail .photo .controls-group {
  position: absolute;
  z-index: 10; }
  body.albums.photo-detail .photo .controls-group.top {
    top: 20px; }
  body.albums.photo-detail .photo .controls-group.bottom {
    bottom: 20px; }
  body.albums.photo-detail .photo .controls-group.left {
    left: 20px; }
  body.albums.photo-detail .photo .controls-group.right {
    right: 20px; }

body.albums.photo-detail .photo .controls {
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.85);
  border: solid 1px rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.5); }
  body.albums.photo-detail .photo .controls > .fa-fw {
    display: block;
    max-height: 1.28571429em;
    line-height: 1.28571429em;
    z-index: 101;
    border-bottom: solid 1px #CCC;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease; }
    body.albums.photo-detail .photo .controls > .fa-fw:last-child {
      border-bottom: none; }
    body.albums.photo-detail .photo .controls > .fa-fw:hover, body.albums.photo-detail .photo .controls > .fa-fw:active, body.albums.photo-detail .photo .controls > .fa-fw.active, body.albums.photo-detail .photo .controls > .fa-fw:focus {
      cursor: pointer;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
  body.albums.photo-detail .photo .controls .popover {
    min-width: 200px; }

body.albums.photo-detail .photo figure.compress img {
  max-height: 98vh; }

body.albums.photo-detail .photo figure.rotate-left img {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg); }

body.albums.photo-detail .photo figure.rotate-right img {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

body.albums.photo-detail .photo figure.rotate-180 img {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

body.albums.photo-detail .photo footer {
  margin-top: 15px; }

body.albums.photo-detail .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.75); }
  body.albums.photo-detail .modal-backdrop .fa-spinner {
    -webkit-animation: fa-spin 1s infinite steps(8), change-color 1s infinite steps(6);
    animation: fa-spin 1s infinite steps(8), change-color 1s infinite steps(6);
    line-height: 100vh; }

#carousel-album,
.carousel-album {
  margin-bottom: 10px; }
  #carousel-album .carousel-inner,
  .carousel-album .carousel-inner {
    padding: 10px 5px;
    margin-left: -5px;
    margin-right: -5px; }
    #carousel-album .carousel-inner .item.next,
    #carousel-album .carousel-inner .item.prev,
    .carousel-album .carousel-inner .item.next,
    .carousel-album .carousel-inner .item.prev {
      top: 10px; }
  #carousel-album .carousel-control,
  .carousel-album .carousel-control {
    background-image: none;
    color: #006699; }
    @media (min-width: 768px) {
      #carousel-album .carousel-control.left,
      .carousel-album .carousel-control.left {
        margin-left: -50px; }
      #carousel-album .carousel-control.right,
      .carousel-album .carousel-control.right {
        margin-right: -50px; } }
  #carousel-album input[type="checkbox"],
  .carousel-album input[type="checkbox"] {
    display: none; }
  #carousel-album .carousel-control,
  .carousel-album .carousel-control {
    max-width: 50px; }
    #carousel-album .carousel-control > .fa,
    .carousel-album .carousel-control > .fa {
      position: absolute;
      top: 50%;
      display: inline-block;
      margin-top: -20px; }
    #carousel-album .carousel-control > .fa-arrow-left,
    .carousel-album .carousel-control > .fa-arrow-left {
      left: 5px; }
    #carousel-album .carousel-control > .fa-arrow-right,
    .carousel-album .carousel-control > .fa-arrow-right {
      right: 5px; }
  #carousel-album input + label,
  .carousel-album input + label {
    display: inline-block; }
    @media (min-width: 768px) {
      #carousel-album input + label,
      .carousel-album input + label {
        margin-bottom: 0; } }
    #carousel-album input + label:hover, #carousel-album input + label:active, #carousel-album input + label:focus,
    .carousel-album input + label:hover,
    .carousel-album input + label:active,
    .carousel-album input + label:focus {
      cursor: pointer;
      border-color: #4095FD; }
    #carousel-album input + label > img,
    .carousel-album input + label > img {
      pointer-events: none; }
    #carousel-album input + label span.h4,
    .carousel-album input + label span.h4 {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 5px;
      margin-bottom: 2px; }
  #carousel-album input + label + .fa,
  .carousel-album input + label + .fa {
    display: none;
    position: absolute;
    top: 5px;
    right: 0.6em;
    color: #32BB5B;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.4), 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px rgba(0, 0, 0, 0.3), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
    text-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3), 0 5px 7px 0 rgba(0, 0, 0, 0.3); }
  #carousel-album input:checked + label,
  .carousel-album input:checked + label {
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3); }
    #carousel-album input:checked + label + .fa,
    .carousel-album input:checked + label + .fa {
      display: block; }

body.builds .twitter-typeahead,
body.builds input[name="q"] {
  display: inline-block;
  width: auto;
  vertical-align: middle; }
  @media (min-width: 650px) {
    body.builds .twitter-typeahead,
    body.builds input[name="q"] {
      min-width: 300px; } }

body.builds .tt-suggestion {
  color: #333333; }

body.builds .preview h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

body.builds .preview .thumbnail {
  position: relative;
  max-width: 1024px; }
  body.builds .preview .thumbnail h4 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 2em;
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    background: -owg-linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
    background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
    background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
    background: -o-linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
    color: #FFFFFF; }

body.builds.detail figure img {
  margin: 0 auto; }

body.builds .fa-spinner {
  -webkit-animation: fa-spin 1s infinite steps(8), change-color 1s infinite steps(6);
  animation: fa-spin 1s infinite steps(8), change-color 1s infinite steps(6); }

body.builds #empty-form {
  display: none; }

body.builds .photo-list input[type="checkbox"] {
  display: none; }

@media (min-width: 768px) {
  body.builds .photo-list input + label, body.builds .photo-list input + label .thumbnail {
    margin-bottom: 0; } }

body.builds .photo-list input + label + .fa {
  display: none;
  position: absolute;
  top: 5px;
  right: 0.6em;
  color: #32BB5B;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4), 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px rgba(0, 0, 0, 0.3), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
  text-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3), 0 5px 7px 0 rgba(0, 0, 0, 0.3); }

body.builds .photo-list input:checked + label {
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3); }
  body.builds .photo-list input:checked + label + .fa {
    display: block; }

body.groupbuilds .detail {
  margin-bottom: 25px; }

body.groupbuilds a.btn {
  color: white;
  font-weight: bolder; }
  body.groupbuilds a.btn:hover {
    background-color: #D46400;
    text-decoration: underline; }

body.groupbuilds .tab-content {
  min-height: 100px;
  margin-bottom: 25px; }

body.groupbuilds #calendar, body.groupbuilds #build-list {
  margin-top: 15px;
  background-color: #f5f5f5;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: solid 1px #e3e3e3;
  padding: 0 15px; }

body.groupbuilds .calendar {
  margin-bottom: 15px; }
  body.groupbuilds .calendar .month-names {
    margin-left: 0;
    margin-right: 0; }
    body.groupbuilds .calendar .month-names .month-name {
      background-color: rgba(0, 102, 153, 0.6);
      padding: 5px inherit;
      color: #FFFFFF; }
      body.groupbuilds .calendar .month-names .month-name:nth-child(odd) {
        background-color: rgba(0, 102, 153, 0.68); }
      body.groupbuilds .calendar .month-names .month-name:first-child {
        -moz-border-radius-topleft: 5px;
        -webkit-border-top-left-radius: 5px;
        border-top-left-radius: 5px; }
      body.groupbuilds .calendar .month-names .month-name:last-child {
        -moz-border-radius-topright: 5px;
        -webkit-border-top-right-radius: 5px;
        border-top-right-radius: 5px; }
  body.groupbuilds .calendar > div:last-child {
    -moz-border-radius-bottomleft: 5px;
    -webkit-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -webkit-border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px; }
  body.groupbuilds .calendar .today-marker {
    width: 3px;
    position: absolute;
    top: -5px;
    height: 100px;
    border: dotted 1px rgba(0, 102, 153, 0.3);
    border-top: none;
    border-bottom: none;
    margin-bottom: -10px;
    z-index: 2; }
  body.groupbuilds .calendar .gb-wrapper {
    overflow: hidden;
    padding: 20px 0 0;
    border-left: solid 1px #CCC;
    border-right: solid 1px #CCC;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    background-color: #FFFFFF; }
    body.groupbuilds .calendar .gb-wrapper .calendar-gb {
      border: solid 1px #CCC;
      text-align: center;
      padding: 0; }
      body.groupbuilds .calendar .gb-wrapper .calendar-gb a {
        display: block;
        padding: 5px;
        color: #FFFFFF;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
        body.groupbuilds .calendar .gb-wrapper .calendar-gb a span.end {
          font-size: 80%;
          line-height: 20px;
          float: right; }
    body.groupbuilds .calendar .gb-wrapper.last {
      padding-bottom: 20px;
      border-bottom: solid 1px #CCC; }

body.groupbuilds .category-icon {
  position: absolute;
  top: 25px;
  right: 50px;
  font-size: 500%;
  color: rgba(0, 102, 153, 0.3); }

body.groupbuilds #build-list .tab-content {
  padding: 15px;
  background-color: #FFFFFF;
  border: solid 1px #DDD;
  border-top: none; }

body.groupbuilds .participants tr .fa-ellipsis-h {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=25);
  opacity: 0.25; }

body.groupbuilds .participants tr .fa-check {
  display: none; }

body.groupbuilds .participants tr.finished .fa-ellipsis-h {
  display: none; }

body.groupbuilds .participants tr.finished .fa-check {
  display: inline-block;
  color: #32BB5B; }

.kit-suggestions {
  margin-top: 15px; }
  .kit-suggestions .add-kit {
    display: none; }
    .kit-suggestions .add-kit a:hover,
    .kit-suggestions .add-kit a:active,
    .kit-suggestions .add-kit a:focus {
      text-decoration: none; }
  .kit-suggestions--no-results .add-kit {
    display: block; }
  .kit-suggestions .h5 {
    display: block; }
  .kit-suggestions .preview .h5 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
    margin-top: 5px; }
  .kit-suggestions .preview .thumbnail {
    position: relative; }
    .kit-suggestions .preview .thumbnail img {
      min-height: 120px; }
    .kit-suggestions .preview .thumbnail h4 {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 2em;
      position: absolute;
      top: 0;
      left: 4px;
      right: 4px;
      background: -owg-linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
      background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
      background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
      background: -o-linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
      color: #FFFFFF; }
  .kit-suggestions .preview input[type="checkbox"],
  .kit-suggestions .preview input[type="radio"] {
    display: none; }
    .kit-suggestions .preview input[type="checkbox"] + label + .fa,
    .kit-suggestions .preview input[type="radio"] + label + .fa {
      display: none;
      position: absolute;
      top: 5px;
      right: 0.6em;
      color: #32BB5B;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.4), 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px rgba(0, 0, 0, 0.3), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
      text-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3), 0 5px 7px 0 rgba(0, 0, 0, 0.3); }
    .kit-suggestions .preview input[type="checkbox"]:checked + label,
    .kit-suggestions .preview input[type="radio"]:checked + label {
      -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 5px 7px 0 rgba(0, 0, 0, 0.3); }
      .kit-suggestions .preview input[type="checkbox"]:checked + label + .fa,
      .kit-suggestions .preview input[type="radio"]:checked + label + .fa {
        display: block; }
  .kit-suggestions .preview label {
    margin-bottom: 20px; }
  .kit-suggestions .preview label:hover {
    cursor: pointer;
    border: solid 1px #4095FD; }
  .kit-suggestions .preview .fa-spinner {
    -webkit-animation: fa-spin 1s infinite steps(8), change-color 1s infinite steps(6);
    animation: fa-spin 1s infinite steps(8), change-color 1s infinite steps(6); }
  .kit-suggestions .preview button + .fa-spinner {
    display: none; }
  .kit-suggestions .center-all {
    display: flex;
    align-items: center;
    justify-content: center; }

.rating .rating__label {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0; }

body.users #form-login {
  margin-top: 75px; }

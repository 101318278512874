.kit-preview {
  @include border-radius(4px);
  border: solid 1px $default-border-color;
  padding: 1em 1em 0;
  margin-bottom: 30px;
  overflow: hidden;

  .button--full {
    margin-left: -15px;
    margin-right: -15px;
  }

  .kit-preview__name {
    @include ellipsis;
    display: block;
    margin-top: 0;
  }

  .kit-preview__image {
    text-align: center;
  }
}

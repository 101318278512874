.button {

  @include border-radius(4px);
  border: 1px solid transparent;

  display: inline-block;

  text-align: center;
  padding: 6px 12px;
  font-weight: bold;

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
  }

  &--icon {
    .fa {
      margin-right: 0.5em;
    }
  }

  &--full {
    display: block;
    min-width: 100%;
  }

  &--disabled {
    &:hover {
      cursor: default;
    }
  }

  &--blue {
    background-color: $main-blue;

    &, &:hover, &:active, &:focus {
      color: $white;
    }
  }

  &--orange {
    background-color: $main-orange;

    &, &:hover, &:active, &:focus {
      color: $white;
    }
  }

  &--light-blue {
    background-color: $main-light-blue;

    &, &:hover, &:active, &:focus {
      color: $white;
    }
  }

  &--fade {
    @include opacity(0.7);
    @include transition(opacity 0.5s ease);
    font-weight: normal;

    &:hover,
    &:active,
    &:focus {
      @include opacity(1);
    }
  }

  &__icon {
    background: $main-orange;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-appearance: none;
    border: none;
    @include border-radius(4px);

    &:hover {
      background: darken($main-orange, 10%);
    }
  }

  &--order {
    @include border-radius(0 4px 4px 0);
  }

  &__add {
    width: 100%;
  }
}

.button-row {
  padding: 5px 0;
  display: flex;
  align-items: center;

  &__text {
    font-size: 10px;
    margin: 0 0 0 5px;
    line-height: 11px;
  }
}

.cart-detail {
  &__page-header {
    margin-bottom: 20px;
  }

  &__table {
    width: 100%;
    border: $border-light;
  }

  &__thead {
    background: $bg-light;

    th {
      padding: 15px;
    }
  }
  &__tbody {

    tr:not(last-child) {
      border-bottom: $border-light;
    }

    td {
      padding: 0 10px;
    }
  }

  &__image {
    width: 50px;
    height: 50px;
  }

  &__quantity {
    width: 100px;
    padding-right: 20px !important;
  }
}

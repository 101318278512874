// shared between different sections of the website
.album-preview {
  position: relative;

  h3 {
    @include ellipsis;
  }

  a.thumbnail + ul.list-inline {
    top: 26px + 20px + 10px + 4px + 2px;
  }
}

body.albums {

  header {

    .dropdown-toggle {
      @include menu-feedback();
    }

    .dropdown-menu {
      top: auto;
      right: 0;
      left: auto;
      @include border-radius(3px);
      background-color: $main-blue;
      padding: 0;

      > li > a {
        color: $white;
        font-weight: bold;
        @include menu-feedback();
      }
    }
  }

  .cover {
    border-color: $main-blue;
    @include raised(1);

    & + ul .set-cover {
      @include opacity(0.2);
    }
  }

  // fineuploader
  .qq-upload-button {
    width: auto;
    display: inline-block;
    padding: 6px;
  }

  #trigger-upload {
    font-weight: bold;
  }

  .qq-upload-drop-area {
    position: static;
    min-height: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: dashed 2px $main-grey;
    background: rgba($main-grey, 0.2);

    > span {
      text-transform: uppercase;
      font-weight: bolder;
      color: rgba($main-grey, 0.8);
      position: static;
      margin-top: 0;
      line-height: 100px;
    }

    &.qq-upload-drop-area-active {
      border-color: $main-orange;
    }
  }

  .qq-upload-list {
    margin-top: 10px;
  }

  .photo-count {
    left: 20px;
    font-size: 65%;
    background-color: rgba($main-green, 0.90);
    line-height: 1;
    @include border-radius(15px);
    padding: 4px 12px;
    width: auto;
  }

  .stats {
    color: rgba($white, 0.5);

    + .controls,
    + div {
      margin-top: 30px;
    }
  }

  .pre-content {
    margin-bottom: 20px;

    .pagination {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.modal-open .modal-backdrop.in {
    @include opacity(0.75);
  }

  #modal-lightbox .carousel-control {
    max-width: 75px;
  }

}

body.albums.photo-detail {

  header {

    margin-bottom: 10px; // instead of 20

    h1 {
      font-size: 24px;
    }

    a.pull-right {
      margin-top: 20px;
    }
  }

  .photo {

    figure {
      padding: 10px;
      background-color: rgba($black, 0.1);
      border: solid 1px rgba($black, 0.25);

      position: relative;

      @include raised(1);

      @include transition(box-shadow 1s ease);

      &:hover,
      &:focus,
      &:active {
        @include raised(2);
      }

      img {
        margin: 0 auto;
      }

      figcaption {
        margin: 10px auto 0;
        width: 90%;
      }
    }

    .controls-group {
      position: absolute;
      z-index: 10;

      @each $position in $positions {
        &.#{$position} {
          #{$position}: 20px;
        }
      }
    }

    .controls {
      margin-bottom: 10px;
      background-color: rgba($white, 0.85);
      border: solid 1px rgba($black, 0.25);
      @include border-radius(6px);
      @include box-shadow(3px 3px 10px 0 rgba($black, 0.5));

      > .fa-fw {
        display: block;
        max-height: 1.28571429em;
        line-height: 1.28571429em;
        z-index: 101;
        border-bottom: solid 1px $default-border-color;
        @include opacity(0.5);
        @include transition(opacity 0.5s ease);

        &:last-child {
          border-bottom: none;
        }

        &:hover,
        &:active,
        &.active,
        &:focus {
          cursor: pointer;
          @include opacity(1);
        }
      }

      .popover {
        min-width: 200px;
      }
    }

    figure.compress img {
      max-height: 98vh;
    }

    figure.rotate-left img {
      @include rotate(-90deg);
    }

    figure.rotate-right img {
      @include rotate(90deg);
    }

    figure.rotate-180 img {
      @include rotate(180deg);
    }

    footer {
      margin-top: 15px;
    }
  }

  .modal-backdrop {
    background-color: rgba(black, 0.75);

    .fa-spinner {
      @include spinner();
      line-height: 100vh;
    }
  }
}


// album picker in upload screen and photo edit
#carousel-album,
.carousel-album {
  margin-bottom: 10px;

  .carousel-inner {
    padding: 10px 5px;
    margin-left: -5px;
    margin-right: -5px;

    .item.next,
    .item.prev {
      top: 10px;
    }
  }

  .carousel-control {
    background-image: none;
    color: $main-blue;

    @media (min-width: 768px) {
      &.left {
        margin-left: -50px;
      }
      &.right {
        margin-right: -50px;
      }
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  .carousel-control {
    max-width: 50px;

    > .fa {
      position: absolute;
      top: 50%;
      display: inline-block;
      margin-top: -20px;
    }

    > .fa-arrow-left {
      left: 5px;
    }

    > .fa-arrow-right {
      right: 5px;
    }
  }

  input {
    + label {
      @include gt-mobile {
        margin-bottom: 0;
      }

      display: inline-block;

      &:hover,
      &:active,
      &:focus {
        cursor: pointer;
        border-color: $main-light-blue;
      }

      > img {
        pointer-events: none;
      }

      span.h4 {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 5px;
        margin-bottom: 2px;
      }
    }

    @include selected-fa;
  }

}

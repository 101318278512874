label {
  font-weight: 500;

  &.required {
    font-weight: 700;
    &:after {
      content: "*";
    }
  }
}

textarea.form-control {
  min-height: 100px;
}

li.error {
  color: red;
  font-weight: bolder;
}

form {
  .submit-wrapper {
    text-align: right;
    margin-bottom: 30px;
  }

  fieldset {

    &.well {
      h3 {
        margin-top: 0;
      }
    }

    &.tab-pane {
      border: solid 1px #DDD;
      border-top: none;
      padding: 15px;
      margin-bottom: 15px;
    }
  }
}


/* buttons */
.btn {
  color: $white;

  &:hover, &:active, &:focus {
    color: $white;
    text-decoration: underline;
  }

  &.btn-default {
    background-color: $main-grey;
  }

  &.fade {
    @include opacity(0.5);
    @include transition(opacity 0.5s ease);

    &:hover,
    &:focus {
      @include opacity(1.0);
    }
  }
}

.bootstrap-select .btn {
  color: $text-color;

  &.btn-default {
    background-color: transparent;

    &:hover {
      background-color: inherit;
      color: inherit;
      text-decoration: none;
    }
  }
}


.formset-form {

  position: relative;

  .DELETE {

    .checkbox {
      position: static;
    }

    input {
      display: none;
    }

    label {
      @include opacity(0.5);
      position: absolute;
      top: 0;
      right: 0;
      z-index: 15;

      &:hover,
      &:focus {
        @include opacity(1.0);
      }
    }

    .delete-backdrop {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
      background-color: rgba($white, 0.75);
    }

    input:checked + label {
      @include opacity(1);

      & + .delete-backdrop {
        display: block;
      }
    }
  }

  &.delete > * {
    @include opacity(0.1); // TODO
  }
}


@mixin slider-thumb() {
  &::-webkit-slider-thumb {
    margin-top: -7px;
    @content;
  }
  &::-moz-range-thumb {
    @content;
  }
  &::-ms-thumb {
    @content;
  }
}


@mixin slider-track() {
  &::-webkit-slider-runnable-track {
    @content;
  }

  &::-moz-range-track {
    @content;
  }

  &::-ms-track {
    @content;
  }
}


input[type="range"] {
  @include appearance(none);
  background: transparent;
  border: none;
  box-shadow: none;
  width: 85%;
  float: left;

  @include slider-thumb {
    @include appearance(none);
    @include transition(background .5s ease);
    border: 1px solid $main-orange;
    border-radius: 50%;
    height: 16px;
    width: 16px;

    background: #ffffff;
    cursor: pointer;
  }

  &:focus,
  &:active,
  &:hover {
    outline: none;
    border: none;
    box-shadow: none;
    @include slider-thumb {
      background: lighten($main-orange, 50);
    }
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    // hides the slider
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  @include slider-track {
    // @include box-shadow(1px 1px 1px $main-blue, 0px 0px 1px $main-light-blue);
    @include border-radius(3px);
    width: 100%;
    height: 6px;
    cursor: pointer;
    border: solid 1px $main-blue;
    background-color: $main-light-blue;
  }

  + output {
    width: 15%;
    float: left;
    text-align: center;
  }
}

.review-preview {

  border: solid 1px $default-border-color;
  padding: 15px;

  &.review-preview--with-ratings {
    .review-preview__body {
        padding-top: 2em;
        max-height: 200px;
    }
  }

  & + .review-preview {
    margin-top: 15px;
  }

  & &__title {
    font-size: 18px;
    margin: 0;
  }

  & &__details {
    color: $main-grey;
  }

  & &__rating {
    margin-left: 1em;
    color: $main-green;
  }

  & &__body {
    max-height: 100px;
    position: relative;
    min-height: 50px;
    overflow: hidden;
    padding-top: 1em;

    &:after {
      content: " ";
      position: absolute;
      width: 100%;
      display: block;
      height: 50px;

      bottom: 0;
      left: 0;

      @include background(linear-gradient(180deg, transparent, $white));
    }
  }

  @include lte-mobile {
    & &__rating {
      display: block;
      margin-left: 0;
      margin-top: 0.5em;
    }

    .button + .button {
      margin-bottom: 0.5em;
    }
  }

  @media(max-width: 992px) {
    & &__details {
      text-align: left;
    }
  }
}

.pagination {
  > .active > a {
    background-color: $main-green;
    border-color: $main-green;
    color: $white;
  }
}

// duplicate of bootstrap
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

.pagination {

  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  @include border-radius(4px);

  > li {
    display: inline;

    > a,
    > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #337ab7;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
    }

    &:first-child > a,
    &:first-child > span {
      margin-left: 0;
      @include border-top-left-radius(4px);
      @include border-bottom-left-radius(4px);
    }

    &:last-child > a,
    &:last-child > span {
      @include border-top-right-radius(4px);
      @include border-bottom-right-radius(4px);
    }
  }

  > .disabled > a,
  > .disabled > span {
    &,
    &:focus,
    &:hover {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
      border-color: #ddd;
    }
  }
}

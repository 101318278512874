
.tt-menu {
  background-color: white;
  @include raised(1);
  @include border-radius(5px);
  padding-top: 2px;
  padding-bottom: 2px;

  .tt-suggestion {
    padding: 2px 10px;

    &:hover,
    &.tt-cursor {
      background-color: rgba($main-grey, 0.25);
      cursor: pointer;
    }
  }
}

.twitter-typeahead {
  width: 100%;
}

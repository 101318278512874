.category-sidenav {
  list-style: none;
  width: 200px;
  border: $border;
  border-radius: 5px;
  padding: 10px 10px 0;
  margin-right: 30px;
  height: 100%;

  &__header {
    font-size: 14px;
  }

  &__item {
    &:not(:last-child) {
      border-bottom: $border-light;
    }
  }

  &__link {
    font-size: 12px;
    color: $text-color;
    display: block;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    &:hover {
      text-decoration: none;
    }

    &--active {
      font-weight: bold;
    }
  }

  &__header {
    font-weight: bold;
  }

  &__subcategories {
    font-size: 12px;
    padding-left: 10px;
  }

  &__subcategory {

    &--active {
      font-weight: bold;
    }
  }
}

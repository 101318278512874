
.ad-container {
  position: relative;
}

#ads {
  $width: 90px;
  @media (max-width: 1340px) {
    z-index: -1;
  }
  position: absolute;
  top: 0;
  right: 0;
  @media (min-width: 1600px) {
    right: 30px;
  }

  a {
    display: block;
    width: $width;
    height: 400px;
    background-color: rgba($black, 0.01); // #DDD;
    margin-bottom: 15px;
    border: dotted 1px #EEE;
  }
}

.kit-suggestions {
  margin-top: 15px;

  .add-kit {

    display: none; // initially, hidden, until no search results turn up

    a:hover,
    a:active,
    a:focus {
      text-decoration: none;
    }
  }

  &--no-results {
    .add-kit {
      display: block;
    }
  }

  .h5 {
    display: block;
  }

  .preview {

    .h5 {
      @include ellipsis;
      margin-bottom: 5px;
      margin-top: 5px;
    }

    .thumbnail {
      position: relative;

      img {
        min-height: 120px;
      }

      h4 {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 2em;
        position: absolute;
        top: 0;
        left: 4px; // padding thumb
        right: 4px; // padding thumb

        @include text-background();
        color: $white;
      }
    }

    input[type="checkbox"],
    input[type="radio"] {
      display: none;
      @include selected-fa();
    }

    label {
      margin-bottom: 20px;
    }

    label:hover {
      cursor: pointer;
      border: solid 1px $main-light-blue;
    }

    .fa-spinner {
      @include spinner();
    }

    button + .fa-spinner {
      display: none;
    }
  }

  .center-all {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

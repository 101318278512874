.category {

  &__search-container {
    margin: 1rem auto;
  }

  &__carousel, &__carousel-item, &__inner {
    height: 300px;
  }

  &__link {
    color: $main-orange;
  }

  &__title {
    color: $text-color;
    margin: 20px auto;
  }

  &__content {
    width: calc(100% - 220px);
  }
}

.subcategories {
  display: flex;
  flex-flow: wrap;

  &__wrapper {
    padding: 10px;
    border: $border;
    border-radius: 10px;
    width: 100%;
  }

  &__header {
    color: $text-color;
  }
}

.subcategory {

  &__card {
    display: flex;
    flex-direction: column;
    width: 150px;
    padding: 20px;
  }

  &__img {
    height: 100px;
    width: 100px;
    border: $border-light;
    border-radius: 5px;
  }

  &__name {
    margin-top: 5px;
  }
}

